import * as acts from '@/store/action.types'
import {mapActions} from 'vuex'
export default {
  name: "Common",
  computed: {
    isTestMode() {
      const { query } = this.$route;
      const { api_key } = query;
      if (!api_key) {
        this[acts.REGISTER_ERROR]("No Public key");
        return
      }
      return api_key.startsWith("pk_test") ? true : false;
    },
  },
  methods: {
    ...mapActions([acts.REGISTER_ERROR]),
    validateQueryParams() {
      const { query } = this.$route;
      const { amount, api_key, email, callback_url, currency } = query;

      if (!api_key) {
        // this.apiState = API_STATE_ENUM.REJECTED;
        this[acts.REGISTER_ERROR]("Invalid Merchant Selected");
        return false;
      }

      if (!email) {
        this[acts.REGISTER_ERROR]("Email is required");
        return false;
      }

      if(!this.validateAmount(amount, currency)){
        return false;
      }

      if (api_key.startsWith("sk_test") || api_key.startsWith("sk_live")) {
        // this.apiState = API_STATE_ENUM.REJECTED;
        this[acts.REGISTER_ERROR]("Invalid Merchant Selected");
        return false;
      }

      if (api_key.startsWith("pk_live") && callback_url) {
        try {
          const address = new URL(callback_url);
          if (address.protocol !== "https") {
            this[acts.REGISTER_ERROR](
              "Please provide a valid callback address. Only https addresses are supported."
            );
            return false;
          }
        } catch (error) {
          // call back invalid
          const { message } = this.$utils.getAxiosErrorResponse(error);
          this.$toast.success(message);
          this.$catchError(error);
          return false;
        }
      }
      return true;
    },
    validateAmount(amount){
      if (!amount || parseInt(amount) / 100 < 1) {
        this[acts.REGISTER_ERROR]("Amount should be at least more than zero");
        return false;
      }
      return true
    }
  },
};
