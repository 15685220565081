<template>
  <button v-bind="$attrs" @click="$emit('click')">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5599 10.7401L20.2099 9.16008C19.9599 8.86008 19.7499 8.30008 19.7499 7.90008V6.20008C19.7499 5.14008 18.8799 4.27008 17.8199 4.27008H16.1199C15.7199 4.27008 15.1499 4.06008 14.8499 3.81008L13.2699 2.46008C12.5799 1.87008 11.4499 1.87008 10.7599 2.46008L9.15988 3.81008C8.85988 4.06008 8.29988 4.27008 7.89988 4.27008H6.16988C5.10988 4.27008 4.23988 5.14008 4.23988 6.20008V7.90008C4.23988 8.29008 4.03988 8.85008 3.78988 9.15008L2.43988 10.7401C1.85988 11.4401 1.85988 12.5601 2.43988 13.2401L3.78988 14.8301C4.03988 15.1201 4.23988 15.6901 4.23988 16.0801V17.7901C4.23988 18.8501 5.10988 19.7201 6.16988 19.7201H7.90988C8.29988 19.7201 8.86988 19.9301 9.16988 20.1801L10.7499 21.5301C11.4399 22.1201 12.5699 22.1201 13.2599 21.5301L14.8399 20.1801C15.1399 19.9301 15.6999 19.7201 16.0999 19.7201H17.7999C18.8599 19.7201 19.7299 18.8501 19.7299 17.7901V16.0901C19.7299 15.6901 19.9399 15.1301 20.1899 14.8301L21.5399 13.2501C22.1499 12.5701 22.1499 11.4401 21.5599 10.7401ZM11.2499 8.13008C11.2499 7.72008 11.5899 7.38008 11.9999 7.38008C12.4099 7.38008 12.7499 7.72008 12.7499 8.13008V12.9601C12.7499 13.3701 12.4099 13.7101 11.9999 13.7101C11.5899 13.7101 11.2499 13.3701 11.2499 12.9601V8.13008ZM11.9999 16.8701C11.4499 16.8701 10.9999 16.4201 10.9999 15.8701C10.9999 15.3201 11.4399 14.8701 11.9999 14.8701C12.5499 14.8701 12.9999 15.3201 12.9999 15.8701C12.9999 16.4201 12.5599 16.8701 11.9999 16.8701Z"
        fill="#292D32"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "Information",
};
</script>

<style lang="scss" scoped>
</style>