<template>
  <button v-bind="$attrs" @click="$emit('click')">
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.3403 16.1111L30.3153 13.7411C29.9403 13.2911 29.6253 12.4511 29.6253 11.8511V9.30109C29.6253 7.71109 28.3203 6.40609 26.7303 6.40609H24.1803C23.5803 6.40609 22.7253 6.09109 22.2753 5.71609L19.9053 3.69109C18.8703 2.80609 17.1753 2.80609 16.1403 3.69109L13.7403 5.71609C13.2903 6.09109 12.4503 6.40609 11.8503 6.40609H9.25531C7.66531 6.40609 6.36031 7.71109 6.36031 9.30109V11.8511C6.36031 12.4361 6.06031 13.2761 5.68531 13.7261L3.66031 16.1111C2.79031 17.1611 2.79031 18.8411 3.66031 19.8611L5.68531 22.2461C6.06031 22.6811 6.36031 23.5361 6.36031 24.1211V26.6861C6.36031 28.2761 7.66531 29.5811 9.25531 29.5811H11.8653C12.4503 29.5811 13.3053 29.8961 13.7553 30.2711L16.1253 32.2961C17.1603 33.1811 18.8553 33.1811 19.8903 32.2961L22.2603 30.2711C22.7103 29.8961 23.5503 29.5811 24.1503 29.5811H26.7003C28.2903 29.5811 29.5953 28.2761 29.5953 26.6861V24.1361C29.5953 23.5361 29.9103 22.6961 30.2853 22.2461L32.3103 19.8761C33.2253 18.8561 33.2253 17.1611 32.3403 16.1111ZM16.8753 12.1961C16.8753 11.5811 17.3853 11.0711 18.0003 11.0711C18.6153 11.0711 19.1253 11.5811 19.1253 12.1961V19.4411C19.1253 20.0561 18.6153 20.5661 18.0003 20.5661C17.3853 20.5661 16.8753 20.0561 16.8753 19.4411V12.1961ZM18.0003 25.3061C17.1753 25.3061 16.5003 24.6311 16.5003 23.8061C16.5003 22.9811 17.1603 22.3061 18.0003 22.3061C18.8253 22.3061 19.5003 22.9811 19.5003 23.8061C19.5003 24.6311 18.8403 25.3061 18.0003 25.3061Z"
        fill="#EE4848"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "InformationRed",
};
</script>

<style lang="scss" scoped>
</style>