<template>
  <button v-bind="$attrs" @click="$emit('click')">
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0C6.72 0 0 6.72 0 15C0 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15C30 6.72 23.28 0 15 0ZM20.25 16.125H12.465L15.045 18.705C15.48 19.14 15.48 19.86 15.045 20.295C14.82 20.52 14.535 20.625 14.25 20.625C13.965 20.625 13.68 20.52 13.455 20.295L8.955 15.795C8.52 15.36 8.52 14.64 8.955 14.205L13.455 9.705C13.89 9.27 14.61 9.27 15.045 9.705C15.48 10.14 15.48 10.86 15.045 11.295L12.465 13.875H20.25C20.865 13.875 21.375 14.385 21.375 15C21.375 15.615 20.865 16.125 20.25 16.125Z"
        fill="white"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "Back",
};
</script>

<style lang="scss" scoped>
</style>