<template>
  <div class="h-screen">
      <template v-if="apiState === API_STATE_ENUM.PENDING ">
      <Loader />
    </template>
    <template v-else>
      <Dashboard />
    </template>
  </div>
</template>

<script>
import Dashboard from "@/components/Dashboard";
import Loader from '@/components/Shared/Loader.vue'

import * as muts from "@/store/mutations.types";
import * as acts from "@/store/action.types";
// import * as gets from "@/store/getters.types"
import { mapMutations, mapActions } from "vuex";
import CommonMixin from '@/mixins/common'
import MixPanelEventMixin from '@/mixins/mix-panel'
import { API_STATE_ENUM, Events, FLUIDCOINS_EVENT, SCREEN_NAME_ENUM, TRANSACTION_STATUS } from "@/services/constants";
import { EventBus } from '@/services/event-bus'
import * as Sentry from "@sentry/vue";
import Pusher from 'pusher-js'

// let _interval = null
let countdownWebWorker = null

export default {
  name: "Main",
  mixins: [CommonMixin, MixPanelEventMixin],
  components: {
    Dashboard,
    Loader
  },
  data() {
    return {
      queryParams: {},
      referrer: "*",
      callbackUrl: "",

      API_STATE_ENUM,
      apiState: API_STATE_ENUM.PENDING,
      widget: {},
    }
  },
  mounted () {
    const { query } = this.$route;
    this.queryParams = query
    const { payment_link_id } = query;

    if(payment_link_id && payment_link_id.startsWith('RELAY')){
      this[muts.SET_RELAY_MODE](true)
      this.fetchRelayData();
    } else {
      this.fetchWidgetData();
    }

    EventBus.$on(Events.RESET_APP, () => {
      this.resetApp()
    })
    EventBus.$on(Events.CLOSE_APP, () => {
      this.handleOnClose()
    })

  },
  methods: {
    ...mapActions([acts.REGISTER_ERROR, acts.SET_CURRENT_ACTIVE_SCREEN]),
    ...mapMutations([
      muts.SET_TRANSACTION,
      muts.SET_COINS,
      muts.SET_PAYMENT_METHOD,
      muts.SET_TIMER,
      muts.SET_TRANSACTION_FAILURE_CONTENT,
      muts.SET_RELAY_MODE,
      muts.SET_RELAY_DATA,
      muts.SET_TRANSACTION_STATUS_PAYLOAD,
      muts.SET_ERROR_MESSAGE,
      muts.SET_SESSION_HAS_EXPIRED,
      muts.SET_CURRENT_ACTIVE_SCREEN,
      muts.SET_FIAT_CURRENCY
    ]),
    async fetchWidgetData() {
      const {
        amount,
        api_key,
        email,
        name,
        referrer,
        payment_link_id,
        metadata,
        phone,
        reference,
        currency,
        order_id
      } = this.queryParams;

      
      if (referrer) {
        this.referrer = referrer;
      }

      if(!this.validateQueryParams()){
        this.apiState = API_STATE_ENUM.RESOLVED;
        return;
      }

      this.apiState = API_STATE_ENUM.PENDING;

      try {
        const payload =  {
          api_key,
          amount: parseInt(amount),
          email,
          customer_name: name,
        }
        if(metadata){
          payload['metadata'] = JSON.parse(metadata)
        }
        if(reference){
          payload['reference'] = reference
        }
        if(phone){
          payload['phone'] = phone
        }
        if(currency){
          payload['currency'] = currency
          this[muts.SET_FIAT_CURRENCY](currency)
        }
        if(payment_link_id){
          payload['payment_link_id'] = payment_link_id
        }
        if(order_id){
          payload['order_id'] = order_id
        }
        
        const { data }  = await this.$api.widget(payload)
        Sentry.setExtras({ data: JSON.stringify(data)})

        this.setupCountdown()
        
        const { widget } = data;
        this.widget = widget;

        this[muts.SET_COINS](widget.coins);
        this[muts.SET_TRANSACTION](widget.transaction);
        this[muts.SET_PAYMENT_METHOD](widget.payment_methods);
        this[muts.SET_FIAT_CURRENCY](widget.transaction.currency)
        this.initializePusher(widget.transaction.channel)
        this.apiState = API_STATE_ENUM.RESOLVED;

      } catch(error) {
        this.catchError(error)
      }

    },
     async fetchRelayData(){
      const { payment_link_id } = this.queryParams;

      this.apiState = API_STATE_ENUM.PENDING;
      try {
        const { data } = await this.$api.getRelayData(payment_link_id)

        Sentry.setExtras({data: JSON.stringify(data)})

        const { relay, addresses } = data
        const COINS = addresses.map( address => ({...address.coin, networks: address.networks}))
        this[muts.SET_RELAY_DATA](relay)
        this[muts.SET_COINS](COINS);
        this[muts.SET_PAYMENT_METHOD](
          [
            {
              iconType: "Wallets",
              method: 'Pay with other wallets',
              slug: 'transfer',
              is_enabled: true
            },
            {
              iconType: "Fluidcoins",
              method: "Pay with Fluidcoins",
              slug: 'fluidcoins',
              is_enabled: false
            },
            {
              iconType: "MetaMask",
              method: "Pay with MetaMask",
              slug: 'metamask',
              is_enabled: false
            },
            {
              iconType: "WalletConnet",
              method: "Pay with Walletconnect",
              slug: 'wallet-connect',
              is_enabled: false
            },
            {
              iconType: "Coinbase",
              method: "Pay with coinbase",
              slug: 'coinbase',
              is_enabled: false
            },
          ]
        )
        this.apiState = API_STATE_ENUM.RESOLVED;

      } catch(error){
        this.catchError(error)
      }
    },
    catchError(error){
      this.apiState = API_STATE_ENUM.REJECTED;
      const { message } = this.$utils.getAxiosErrorResponse(error);
      if(message === 'Network Error'){
        this[acts.REGISTER_ERROR]('Could not set up the payment widget, please check your internet connection and try again')
      } else {
        this[acts.REGISTER_ERROR](message)
      }
      this.$toast.error(message)
      this.$catchError(error);
    },
    initializePusher(channelId){
      try {
        const pusher = new Pusher(process.env.VUE_APP_PUSHER_API_KEY, {
          cluster: process.env.VUE_APP_PUSHER_CLUSTER
        })

        const vm = this;
        const channel = pusher.subscribe(channelId)
        channel.bind('transaction.received', (transaction) => {
          if(transaction && (transaction.paid_in_full || transaction.underpaid || transaction.overpaid)) {
            vm.removeIntervals();
            vm.handleOnSuccessMessage(transaction);
          }
        })
      }
      catch (error) {
        const { message } = this.$utils.getAxiosErrorResponse(error);
        this[acts.REGISTER_ERROR](message || error)
      }
    },
    handleSessionExpired(){
      this[muts.SET_SESSION_HAS_EXPIRED](true)
      this.removeIntervals();
      let eventData = {
        type: FLUIDCOINS_EVENT.ERROR,
        data: {
          type: "expired",
        },
      };
      this.$utils.postMessage(eventData, this.referrer);
    },
    setupCountdown(){
      if(window.Worker){
        countdownWebWorker = new Worker('/workers/countdown.js')
        countdownWebWorker.postMessage('')
        countdownWebWorker.onmessage = ( { data }) => {
          
          const {timeString, sessionExpired }   = data 
          this[muts.SET_TIMER](timeString)
          
          if(sessionExpired){
            this.handleSessionExpired()
          }
        }
      }
    },
    handleOnSuccessMessage(transaction) {
      let status = "";
      if (transaction.paid_in_full) {
        status = TRANSACTION_STATUS.PAID_IN_FULL;
        this[muts.SET_TRANSACTION_STATUS_PAYLOAD]({
          paid_in_full: true,
        });
      }

      if (transaction.underpaid) {
        status = TRANSACTION_STATUS.UNDERPAID;
        this[muts.SET_TRANSACTION_STATUS_PAYLOAD]({
          underpaid: true,
        });
      }

      if (transaction.overpaid) {
        status = TRANSACTION_STATUS.OVERPAID;
        this[muts.SET_TRANSACTION_STATUS_PAYLOAD]({
          overpaid: true,
        });
      }
      this[acts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.PAYMENT_STATUS)

      let eventData = {
        type: FLUIDCOINS_EVENT.SUCCESS,
        data: {
          reference: transaction.reference,
          coin: transaction.coin,
          human_readable_amount: transaction.human_readable_amount,
          payment_status: status,
        },
      };
      this.$utils.postMessage(eventData, this.referrer);

      if(this.callbackUrl){
        let callBackEventData = {
          type: FLUIDCOINS_EVENT.CALLBACK,
          data: {
            url: this.callbackUrl,
          },
        };
        this.$utils.postMessage(callBackEventData, this.referrer);
      }
    },
    handleOnClose(){
      let eventData = {
        type: FLUIDCOINS_EVENT.WIDGET_CLOSED,
        data: { timestamp: Date.now() },
      };
      this.$utils.postMessage(eventData, this.referrer);
    },
    removeIntervals() {
      if(countdownWebWorker){
        countdownWebWorker.terminate()
      }
      
    },
    resetApp(){
      this[muts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.DASHBOARD)
      this[muts.SET_SESSION_HAS_EXPIRED](false)
      this.removeIntervals();
      this.fetchWidgetData();
    }
  },
  destroyed() {
    EventBus.$off()
    this.removeIntervals();
  },
};
</script>

<style>
</style>