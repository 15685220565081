<template>
    <div class="rounded-medium bg-white flex-1 flex flex-col justify-between h-full w-full max-h-full overflow-hidden pb-4">
        <slot />
        <!-- overflow-y-scroll no-scrollbar -->
    </div>
</template>

<script>
    export default {
        name: 'DashboardBase'
    }
</script>

<style lang="css" scoped>
 
</style>
