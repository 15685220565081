<template>
    <component :is="coinType" class="w-full h-full" />
</template>

<script>
    export default {
        name: 'Icon',
        components: {
            Success: () => import("@/components/Icons/simulation-icons/Success.vue"),
            Failed: () => import("@/components/Icons/simulation-icons/Failed.vue"),
            Overpaid: () => import('@/components/Icons/simulation-icons/Overpaid.vue'),
            Underpaid: () => import('@/components/Icons/simulation-icons/Overpaid.vue')
        },
        props: {
            coin: {
                type: String,
                required: true,
            },
        },
        computed: {
            coinType() {
                switch (this.coin.toLowerCase()) {
                    case 'success':
                        return 'Success'
                    case 'underpaid':
                        return 'Underpaid'
                    case 'overpaid':
                        return 'Overpaid'
                    case 'failed':
                        return 'Failed'
                    default:
                        return "Failed";
                }
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>