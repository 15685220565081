<template>
  <div class="flex flex-col w-full h-full px-4 md:px-8 pt-5">
    <div class="w-11/12 md:w-9/12 mx-auto flex flex-col flex-1 pb-5">
      <div
        class="
          py-2
          relative
          rounded-medium
          border border-darkGrey
          px-3
          flex
          items-center
          self-center
        "
        @click="toggleModal"
        role="button"
        aria-label="Change Asset/Network"
      >
        <div
          class="absolute -top-2 flex justify-center items-center w-full left-0"
        >
          <div class="bg-navyBlue px-2 rounded-xl">
            <p class="text-xs font-semibold text--primary-gradient">NETWORK</p>
          </div>
        </div>
        <div class="h-9 w-9 pr-2">
          <img
            :src="selectedCoin.logo || selectedCoin.image"
            class="w-full h-full"
            :alt="selectedCoin.code"
          />
        </div>
        <p class="text-black text-base md:text-xl font-medium mx-2">
          {{ selected_network.name }}
        </p>
        <chevron />
      </div>
      <div class="h-60 mt-2 relative">
        <img
          alt="qr code"
          :src="`https://chart.googleapis.com/chart?cht=qr&chs=250x250&chl=${selected_network.address}`"
          class="w-full h-full object-cover"
        />
        <div
          class="
            absolute
            w-full
            h-full
            top-0
            bottom-0
            left-0
            right-0
            flex flex-col
            items-center
            justify-center
          "
        >
          <div class="w-8 h-8 p-1 bg-white rounded-full overflow-hidden">
            <img
              :src="selectedCoin.logo || selectedCoin.image"
              class="w-full h-full object-contain"
              :alt="selectedCoin.code"
            />
          </div>
        </div>
      </div>
      <div class="flex space-x-3 mb-4">
        <information-red class="flex-none" />
        <p class="text-black font-semibold text-xs">
          To avoid losing your deposit, please send only {{ coinToSend }} to
          this address over the {{ selected_network.name }} network.
        </p>
      </div>
      <div class="mt-2 md:mt-3">
        <div class="bg-black rounded-large pt-2 pb-4 px-8">
          <p class="text-grey text-xs md:text-base text-center font-medium">
            ADDRESS
          </p>
          <div class="flex justify-between items-center mt-1">
            <p class="text-grey text-sm md:text-xl font-semibold tracking-wide">
              {{ shortenedAddress }}
            </p>
            <div
              role="button"
              aria-label="COPY"
              class="
                rounded-3xl
                bg-grey
                text-xs text-black
                px-2
                md:px-4
                py-1
                md:py-2
                font-medium
              "
              @click="copyAddress(selected_network)"
            >
              COPY
            </div>
          </div>
        </div>
        <p
          role="button"
          aria-label="memo"
          class="font-medium text-center text-black mt-4"
          @click="showExtraInfoModal = true"
          v-if="updatedCoin.memo"
        >
          Show Memo
        </p>
        <p
          role="button"
          aria-label="destination tag"
          class="font-medium text-center text-black mt-4"
          @click="showExtraInfoModal = true"
          v-else-if="hasDestinationTag"
        >
          Show Destination Tag
        </p>
      </div>
    </div>

    <button @click="confirmPayment" class="secondary text-white mt-auto">
      <span class="text--primary-gradient font-medium">CONFIRM PAYMENT </span
      ><span class="text--primary-gradient font-medium ml-1" v-if="timer"
        >({{ timer }})</span
      >
    </button>
    <!-- <div class="pb-0"></div> -->
    <Modal v-if="showModal" @close="toggleModal">
      <div
        class="
          bg-white
          w-11/12
          md:w-9/12
          mx-auto
          rounded-medium
          pt-8
          overflow-hidden
          relative
        "
        @click.stop
      >
        <close-icon
          @click="toggleModal"
          role="button"
          aria-label="cancel"
          aria-labelledby="close"
          class="absolute right-5"
        />
        <form class="px-5" @submit.prevent="submit" id="updateForm">
          <p class="text-base text-black text-center font-medium">
            SELECT COIN
          </p>
          <div class="space-y-6 my-8">
            <div class="relative">
              <label
                for="coin"
                class="absolute left-6 top-4 text-grey400 text-sm tracking-wide"
                >Choose Asset</label
              >
              <select
                v-model="updatedCoin"
                name="coin"
                class="font-medium pt-9 pb-4"
              >
                <option v-for="coin in coins" :value="coin" :key="coin.code">
                  {{ coin.code }}
                </option>
              </select>
            </div>
            <div class="relative">
              <label
                for="coin"
                class="absolute left-6 top-4 text-grey400 text-sm tracking-wide"
                >Choose Network</label
              >
              <select
                v-model="updateSelectedNetworkName"
                name="coin"
                class="font-medium pt-9 pb-4"
              >
                <option
                  v-for="network in updatedCoin.networks"
                  :key="network.name"
                >
                  {{ network.name }}
                </option>
              </select>
            </div>
          </div>
        </form>
        <button
          type="submit"
          form="updateForm"
          class="
            text-base text-black
            bg-grey100
            py-5
            text-center
            font-medium
            tracking-wider
            w-full
          "
        >
          DONE
        </button>
      </div>
    </Modal>
    <AddressExtraInfoModal
      @close="showExtraInfoModal = false"
      v-if="showExtraInfoModal"
      :hasDestinationTag="hasDestinationTag"
      :text="updatedCoin.memo || updatedCoin.destination_tag"
    />
  </div>
</template>

<script>
import Modal from "@/components/Modal/index.vue";
import * as gets from "@/store/getters.types";
import * as muts from "@/store/mutations.types";
import * as acts from "@/store/action.types";
import { mapGetters, mapMutations, mapActions } from "vuex";
import CopyMixin from "@/mixins/copy-to-clipboard.js";
import { MIX_PANEL_EVENT, SCREEN_NAME_ENUM } from "@/services/constants";
import AddressExtraInfoModal from "@/components/Wallet/AddressExtraInfoModal.vue";
import { EventBus } from "@/services/event-bus";
import * as Sentry from "@sentry/vue";
import CloseIcon from "../Icons/CloseIcon.vue";
import InformationRed from "../Icons/InformationRed.vue";
import Chevron from "../Icons/Chevron.vue";
export default {
  name: "AddressDetails",
  mixins: [CopyMixin],
  components: {
    Modal,
    AddressExtraInfoModal,
    Chevron,
    CloseIcon,
    InformationRed,
  },
  data() {
    return {
      showModal: false,
      showExtraInfoModal: false,
      updateSelectedNetworkName: "",
      updatedCoin: {},
    };
  },
  computed: {
    ...mapGetters([
      gets.SELECTED_NETWORK,
      gets.SELECTED_COIN,
      gets.SESSION_HAS_EXPIRED,
      gets.TIMER,
      gets.COINS,
    ]),
    hasDestinationTag() {
      return (
        this.updatedCoin.destination_tag &&
        parseInt(this.updatedCoin.destination_tag) > 0
      );
    },
    shortenedAddress() {
      const address = this.selected_network && this.selected_network.address;
      if (address) {
        return `${address.substring(0, 6)}...${address.substring(
          address.length - 5
        )}`;
      }
      return "Not available";
    },
    coinToSend() {
      return this.$utils.getCoinToSendTo(
        this.updatedCoin.code,
        this.selected_network.name
      );
    },
  },
  watch: {
    updatedCoin(new_coin) {
      try {
        Sentry.setExtras({ updatedCoin: new_coin });

        const { networks } = new_coin;
        if (networks.length > 0) {
          this.updateSelectedNetworkName = networks[0].name;
        }
      } catch (error) {
        this.$catchError(error);
      }
    },
  },
  mounted() {
    this.updatedCoin = this.selectedCoin;
  },
  methods: {
    ...mapMutations([muts.SET_SELECTED_COIN, muts.SET_SELECTED_NETWORK]),
    ...mapActions([acts.SET_CURRENT_ACTIVE_SCREEN]),
    confirmPayment() {
      this[acts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.PROCESSING);
    },
    submit() {
      const coin = this.updatedCoin;
      const { networks } = coin;
      this[muts.SET_SELECTED_COIN](coin);
      this[muts.SET_SELECTED_NETWORK](
        networks.find(
          (network) => network.name === this.updateSelectedNetworkName
        ) || {}
      );
      this.showModal = false;

      EventBus.$emit(MIX_PANEL_EVENT.CHANGED_COIN, { code: coin.code });
      EventBus.$emit(MIX_PANEL_EVENT.SELECTED_COIN, {
        code: coin.code,
        network: this.updateSelectedNetworkName,
      });
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    copyAddress(network) {
      this.copy(network.address);
      EventBus.$emit(MIX_PANEL_EVENT.COPY_ADDRESS, network);
    },
  },
};
</script>

<style lang="css" scoped>
</style>