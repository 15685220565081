export const API_STATE_ENUM  = {
  IDLE: 'idle',
  PENDING: 'pending',
  REJECTED: 'rejected',
  RESOLVED: 'resolved'
}

export const SCREEN_NAME_ENUM = {
  DASHBOARD: 'dashboard',
  CHOOSE_COIN: 'choose_coin',
  CHOOSE_METAMASK_ACCOUNT: 'choose_metamask_account',
  COIN_ADDRESS: 'coin_address',
  PROCESSING: 'processing',
  ERROR: 'error',
  PAYMENT_STATUS: 'payment_status',
  SIMULATE: 'simulate',
  WALLET_CONNECT: 'wallet_connect',
}

export const FALL_BACK_PAYMENT_METHODS = [
  {
    method: 'Pay with Transfer',
    slug: 'transfer'
  },
  {
    method: 'Pay with Fluidcoins',
    slug: 'fluidcoins'
  },
  {
    method: 'Pay with MetaMask',
    slug: 'metamask'
  },
  {
    method: 'Pay with Coinbase',
    slug: 'coinbase'
  },
  {
    method: 'Pay with WalletConnect',
    slug: 'wallet-connect'
  },
  
  
]

export const Events = {
  RESET_APP: 'reset_app',
  CLOSE_APP: 'close_app'
}

export const TRANSACTION_STATUS = {
  PAID_IN_FULL: 'paid_in_full',
  UNDERPAID: 'underpaid',
  OVERPAID: 'overpaid'
}

export const FLUIDCOINS_EVENT = {
  SUCCESS: 'fluidcoins.success',
  WIDGET_CLOSED: 'fluidcoins.widget_closed',
  CALLBACK: 'fluidcoins.call_callback_url',
  ERROR: 'fluidcoins.error'
}

export const MIX_PANEL_EVENT = {
  PAYMENT_METHOD_CLICKED: 'Payment Method Clicked',
  PAYMENT_METHOD_SELECTED: 'Payment Method Selected',
  UNAVAILABLE_COIN: 'Unavailable Coin',
  CLOSE_WIDGET: 'Close Widget',
  COPY_ADDRESS: 'Copy Address',
  COPY_MEMO: 'Copy Memo',
  COPY_DESTINATION_TAG: 'Copy Destination tag',
  SELECTED_COIN: 'Selected Coin',
  CHANGED_COIN: 'Changed Coin'
}


// export const ERC20_BALANCE_OF_ABI = 

export const ER20_ABI = [
  {
    'constant': false,
    'inputs': [
        {
            'name': '_to',
            'type': 'address'
        },
        {
            'name': '_value',
            'type': 'uint256'
        }
    ],
    'name': 'transfer',
    'outputs': [
        {
              'name': '',
              'type': 'bool'
          }
      ],
      'payable': false,
      'stateMutability': 'nonpayable',
      'type': 'function'
  },
  {
    'constant': true,
    'inputs': [
        {
            'name': '_owner',
            'type': 'address'
        }
    ],
    'name': 'balanceOf',
    'outputs': [
        {
            'name': 'balance',
            'type': 'uint256'
        }
    ],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
  }
]

export const TOKEN_ADDRESSES = [
  {
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    token: 'USDT'
  },
  {
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    token: 'USDC'
  },
  {
    address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    token: 'DAI'
  }
]

export const WEI_UNITS = {
  USDC: 6,
  USDT: 6,
  DAI: 18
}

export const WALLET_CONNECT_CONFIG = {
  rpc: {
    1 : 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    3: 'https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
  }
 }

 export const CHAINS = [1]
 export const  INFURA_ID = '9aa3d95b3bc440fa88ea12eaa4456161'