<template>
    <div class="w-screen h-screen flex flex-col items-center justify-center md:px-2  relative">
        <div class="w-full md:max-w-large main relative">
            <div class="bg-dashboard-pattern md:rounded-large bg-black w-full h-full flex flex-col relative overflow-hidden py-3 px-3">
                <div class="flex-none pt-2 pb-2">
                    <Navigation />
                    <div class="mt-10">
                        <div class="px-5" v-if="canShowQuickSummary || canShowQuickSummaryWithMerchant">
                            <QuickSummary v-if="canShowQuickSummary"/>
                            <QuickSummaryWithMerchant v-if="canShowQuickSummaryWithMerchant" />
                        </div>
                    </div>
                </div>
                <DashboardBase>
                    <div class="overflow-y-scroll base__first">
                        <PaymentSelect v-if="dashboardScreenActive" @wallet-connector="handleConnector"/>
                        <CoinSelect v-if="chooseCoinScreenActive" />
                        <template v-if="chooseMetaMaskScreenActive">
                            <MetamaskScreen v-if="!$utils.isMobile()" />
                            <MetaMaskMobile v-else/>
                        </template>
                        <WalletConnect v-if="walletConnectActive" :connector="connector" />
                        <AddressDetails v-if="addressScreenActive" />
                        <TestSimulation v-if="canShowSimulate" />
                        <Processing v-if="canShowProcessing"/>
                        <Success v-if="canShowSuccess" />
                        <ErrorBoundary v-if="canShowError" />
                    </div>
                    <div v-if="canShowSecuredBy" class="base__last flex flex-col justify-end">
                        <SecuredBy />
                    </div>
                </DashboardBase>
                <LinkExpired v-if="session_has_expired" />
                <CloseModal v-if="can_show_close_modal" />
            </div>
        </div>
    </div>
</template>

<script>
import DashboardBase from '@/components/Dashboard/Base.vue'
import PaymentSelect from '@/components/Payments/PaymentSelect.vue'
import SecuredBy from '@/components/Shared/SecuredBy.vue'
import CoinSelect from '@/components/Payments/CoinSelect.vue'
import MetamaskScreen from '@/components/MetaMask/index.vue'
import MetaMaskMobile from '@/components/MetaMask/Mobile.vue'
import QuickSummaryWithMerchant from '@/components/Dashboard/QuickSummaryWithMerchant.vue'
import QuickSummary from '@/components/Dashboard/QuickSummary.vue'
import AddressDetails from '@/components/Wallet/AddressDetails.vue'
import LinkExpired from '@/components/Transaction/LinkExpired.vue'
import CloseModal from '@/components/Dashboard/CloseModal.vue'
import Processing from '@/components/Transaction/Processing.vue'
import Success from '@/components/Transaction/Success.vue'
import Navigation from '@/components/Dashboard/Navigation.vue'
import TestSimulation from '@/components/Payments/TestSimulation.vue'
import ErrorBoundary from '@/components/Dashboard/ErrorBoundary.vue'
import { SCREEN_NAME_ENUM } from '@/services/constants'
import WalletConnect from '@/components/WalletConnect/index.vue'
import * as gets from "@/store/getters.types"
import { mapGetters} from 'vuex'

    export default {
        name: 'Dashboard',
        components: {
            Navigation,
            DashboardBase,
            PaymentSelect,
            CoinSelect,
            QuickSummary,
            QuickSummaryWithMerchant,
            AddressDetails,
            LinkExpired,
            CloseModal,
            Processing,
            TestSimulation,
            Success,
            SecuredBy,
            ErrorBoundary,
            MetamaskScreen,
            MetaMaskMobile,
            WalletConnect
        },
        data() {
            return {
                connector: null
            }
        },
        computed: {
            ...mapGetters([gets.CURRENT_ACTIVE_SCREEN, gets.SESSION_HAS_EXPIRED, gets.CAN_SHOW_CLOSE_MODAL]),
            dashboardScreenActive(){
                return this.current_active_screen === SCREEN_NAME_ENUM.DASHBOARD
            },
            chooseCoinScreenActive() {
                return this.current_active_screen === SCREEN_NAME_ENUM.CHOOSE_COIN
            },
            chooseMetaMaskScreenActive() {
                return this.current_active_screen === SCREEN_NAME_ENUM.CHOOSE_METAMASK_ACCOUNT
            },
            walletConnectActive() {
                return this.current_active_screen === SCREEN_NAME_ENUM.WALLET_CONNECT
            },
            addressScreenActive(){
                return this.current_active_screen === SCREEN_NAME_ENUM.COIN_ADDRESS
            },
            canShowQuickSummary(){
                return this.current_active_screen === SCREEN_NAME_ENUM.COIN_ADDRESS
            },
            canShowSecuredBy() {
                return this.dashboardScreenActive || this.chooseCoinScreenActive || this.canShowProcessing || this.canShowSimulate || this.chooseMetaMaskScreenActive || this.walletConnectActive
            },
            canShowProcessing(){
                return this.current_active_screen === SCREEN_NAME_ENUM.PROCESSING
            },
            canShowSuccess(){
                return this.current_active_screen === SCREEN_NAME_ENUM.PAYMENT_STATUS
            },
            canShowSimulate(){
                return this.current_active_screen === SCREEN_NAME_ENUM.SIMULATE
            },
            canShowError(){
                return this.current_active_screen === SCREEN_NAME_ENUM.ERROR
            },
            canShowQuickSummaryWithMerchant(){
                return this.dashboardScreenActive || this.canShowSimulate || this.canShowError || this.chooseMetaMaskScreenActive || this.walletConnectActive
            },
        },
        methods: {
            handleConnector(connector) {
                this.connector = connector
            }
        },
    }
</script>

<style lang="css" scoped>
    @media (max-width: 639px) {
        .main {
            height: 100vh;
        }
    }

    @media (min-width: 640px){
        .main{
            height: min(93vh, 58.2rem);
            /* max-height: 58.2rem; */
        }
    }
    

    .base__first{
        flex: 10;
        
    }
   
    .base__last{
        flex: 1;
    }
    
</style>