/* eslint-disable no-unused-vars */
<template>
    <div class="px-4 md:px-8 pt-8 h-full flex flex-col">
        <p class="text-base text-black text-center font-medium mt-8">SELECT COIN</p>
         <div class="space-y-4 mt-10 mb-7">
            <p class="w-9/12 mx-auto text-grey400 my-40" v-if="!sortedCoins.length">Ouch! 😢 Looks like we don't have that coin yet. <a href="https://airtable.com/shrdCzCntmLnFlfgU" aria-label="form" rel="noreferrer noopener" target="_blank" class="underline">Tell us here</a> so we can add it soon.</p>
            <CoinSelectItem :logoUrl="coin.logo || coin.image" :title="coin.code" @selected="handleSelect(coin)" v-for="coin in sortedCoins" :key="coin.code"/>
        </div>
        <p class="text-black text-center mt-auto text-base font-medium tracking-wide" v-if="!is_relay_mode">Link will expire in {{ timer }}</p>
    </div>
</template>

<script>
import MetaMaskMixin from '@/mixins/meta-mask.js'
import CoinSelectItem from '@/components/Payments/ListItem.vue'
import { SCREEN_NAME_ENUM, TOKEN_ADDRESSES } from '@/services/constants'
import * as acts from "@/store/action.types"

    export default {
        name: 'MetaMaskMobile',
        mixins: [MetaMaskMixin],
        components: {
            CoinSelectItem,
        },
        data() {
            return {
                
            }
        },
        methods: {
            handleSelect(coin) {
                try {
                    const {networks , amount, code} = coin
                    const erc20Network =  networks.find( network => network.name === 'ERC20')
                    const tokenAddress = TOKEN_ADDRESSES.find( token => token.token === code)
                    const contractAddress = tokenAddress.address
                    const toAddress = erc20Network.address
                    
                    this[acts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.PROCESSING)
                    window.open(`https://metamask.app.link/send/${contractAddress}@1/transfer?address=${toAddress}&uint256=${amount}`)

                } catch( error){
                    this.$catchError(error);
                }
            }
        },

    }
</script>

<style lang="scss" scoped>

</style>