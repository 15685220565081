<template>
  <div>
    <button
      :id="slug"
      class="payment-method container"
      :class="{ 'cursor-not-allowed': !is_enabled }"
      @click="handleClick"
      :aria-label="method"
    >
      <div class="flex flex-1">
        <div class="h-8 w-8">
          <img
            :src="imgURL"
            class="w-full h-full object-contain"
            :alt="method"
          />
        </div>
        <div class="ml-4">
          <p class="text-sm md:text-base text-black font-medium text-left">
            {{ method }}
          </p>
          <p
            class="text-xs md:text-sm text-left"
            :class="{ 'font-semibold': !is_enabled, 'font-medium': is_enabled }"
          >
            {{ is_enabled ? description : getLabel() }}
          </p>
        </div>
      </div>
      <p v-if="!is_enabled" class="text-lg md:text-3xl">🚀</p>
    </button>
  </div>
</template>

<script>
import * as gets from "@/store/getters.types";
import { mapGetters } from "vuex";
export default {
  name: "PaymentMethodItem",
  props: {
    slug: {
      type: String,
      required: true,
    },
    is_enabled: {
      type: Boolean,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([gets.IS_RELAY_MODE]),
    isCoinbase() {
      return this.slug.toLowerCase() === "coinbase";
    },
    imgURL() {
      switch (this.slug.toLowerCase()) {
        case "transfer":
          return "https://res.cloudinary.com/fluidcoins/image/upload/v1638874241/widget/methods/transfer_oafqqh.png";
        case "coinbase":
          return "https://res.cloudinary.com/fluidcoins/image/upload/v1638874241/widget/methods/coinbase_lolzhu.png";
        case "fluidcoins":
          return "https://res.cloudinary.com/fluidcoins/image/upload/v1638879754/widget/methods/fluidcoins_x4_hdw5o8.png";
        case "metamask":
          return "https://res.cloudinary.com/fluidcoins/image/upload/v1638874241/widget/methods/metamask_zrnnbl.png";
        case "wallet-connect":
          return "https://res.cloudinary.com/fluidcoins/image/upload/v1638874241/widget/methods/walletconnet_dhipld.svg";
        default:
          return "https://res.cloudinary.com/fluidcoins/image/upload/v1638879754/widget/methods/fluidcoins_x4_hdw5o8.png";
      }
    },
    description() {
      switch (this.slug.toLowerCase()) {
        case "transfer":
          return "From External Wallet";
        case "coinbase":
          return "From Coinbase Balance";
        case "fluidcoins":
          return "From Fluidcoins Balance";
        case "metamask":
          return "From MetaMask";
        case "wallet-connect":
          return "Pay from 10+ non-custodial wallets";
        default:
          return "";
      }
    },
    isTestMode() {
      const { query } = this.$route;
      const { api_key } = query;
      return api_key.startsWith("pk_test") ? true : false;
    },
  },
  methods: {
    handleClick() {
      if (this.is_enabled) {
        this.$emit("select", this.slug);
      }
    },
    getLabel() {
      if (
        ["metamask", "wallet-connect"].includes(this.slug.toLowerCase()) &&
        this.isTestMode &&
        !this.is_relay_mode
      ) {
        return "Not available in test mode ";
      }

      return "Coming Soon";
    },
  },
};
</script>

<style lang="css" scoped>
.container {
  min-height: 92px;
}
.flex-short {
  flex: 0.3;
}
.payment-method {
  @apply flex justify-between items-center bg-grey rounded-moderate px-5 md:px-7 py-3 md:py-4 box-border;
}
</style>
