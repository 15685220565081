<template>
  <button @click="$emit('click')" v-bind="$attrs">
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 2.5C8.1125 2.5 2.5 8.1125 2.5 15C2.5 21.8875 8.1125 27.5 15 27.5C21.8875 27.5 27.5 21.8875 27.5 15C27.5 8.1125 21.8875 2.5 15 2.5ZM20.075 14.0875L15.6625 18.5C15.475 18.6875 15.2375 18.775 15 18.775C14.7625 18.775 14.525 18.6875 14.3375 18.5L9.925 14.0875C9.5625 13.725 9.5625 13.125 9.925 12.7625C10.2875 12.4 10.8875 12.4 11.25 12.7625L15 16.5125L18.75 12.7625C19.1125 12.4 19.7125 12.4 20.075 12.7625C20.4375 13.125 20.4375 13.7125 20.075 14.0875Z"
        fill="#98A2B3"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "Chevron",
};
</script>

<style lang="scss" scoped>
</style>