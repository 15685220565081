<template>
  <div class="pt-9" id="">
    <p
      class="
        text-black
        uppercase
        text-sm
        md:text-base
        text-center
        font-medium
        tracking-wider
      "
    >
      Select Payment Option
    </p>
    <div class="px-5 md:px-8 mt-5 space-y-4 md:space-y-5">
      <PaymentMethodItem
        v-for="method in sortedMethods"
        :key="method.slug"
        v-bind="method"
        @select="onSelect"
      />
      <a
        ref="onRampUrlRef"
        :href="onRampUrl"
        target="_blank"
        class="hidden"
      ></a>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import PaymentMethodItem from "@/components/Payments/PaymentMethodItem.vue";

import * as gets from "@/store/getters.types";
import * as muts from "@/store/mutations.types";
import * as acts from "@/store/action.types";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { MIX_PANEL_EVENT, SCREEN_NAME_ENUM } from "@/services/constants";
import CommonMixin from "@/mixins/common";
import { EventBus } from "@/services/event-bus";

import { ethers } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import WalletConnectMixin from "@/mixins/WalletConnect.js";
import CoinbaseMixin from "@/mixins/coinbase.js";
let instance;
export default {
  name: "PaymentSelect",
  mixins: [CommonMixin, WalletConnectMixin, CoinbaseMixin],
  data() {
    return {
      coinbaseInstance: null,
    };
  },
  components: {
    PaymentMethodItem,
  },
  computed: {
    ...mapGetters([gets.PAYMENT_METHODS, gets.IS_RELAY_MODE]),
    sortedMethods() {
      return [...this.payment_methods].sort(
        (a, b) => b.is_enabled - a.is_enabled
      );
    },
  },

  methods: {
    ...mapMutations([muts.SET_METAMASK_ACCOUNT]),
    ...mapActions([acts.SET_CURRENT_ACTIVE_SCREEN]),
    async onSelect(slug) {
      EventBus.$emit(MIX_PANEL_EVENT.PAYMENT_METHOD_CLICKED, { slug });
      switch (slug) {
        case "transfer":
          if (this.isTestMode && !this.is_relay_mode) {
            this[acts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.SIMULATE);
            return;
          }
          EventBus.$emit(MIX_PANEL_EVENT.PAYMENT_METHOD_SELECTED, { slug });
          this[acts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.CHOOSE_COIN);
          break;

        case "metamask":
          {
            if (!this.$utils.isMobile()) {
              const provider = await detectEthereumProvider();
              if (provider && provider.isMetaMask) {
                const provider = new ethers.providers.Web3Provider(
                  window.ethereum
                );
                const network = await provider.getNetwork();
                if (network.name === "homestead") {
                  const accounts = await provider.send(
                    "eth_requestAccounts",
                    []
                  );
                  if (accounts && accounts.length > 0) {
                    EventBus.$emit(MIX_PANEL_EVENT.PAYMENT_METHOD_SELECTED, {
                      slug,
                    });

                    this[muts.SET_METAMASK_ACCOUNT](accounts[0]);
                    this[acts.SET_CURRENT_ACTIVE_SCREEN](
                      SCREEN_NAME_ENUM.CHOOSE_METAMASK_ACCOUNT
                    );
                  }
                } else {
                  this.$toast.info("Please switch to Ethereum Mainnet");
                }
              } else {
                this.$toast.info("Install Metamask to continue!");
              }
            } else {
              EventBus.$emit(MIX_PANEL_EVENT.PAYMENT_METHOD_SELECTED, { slug });
              this[acts.SET_CURRENT_ACTIVE_SCREEN](
                SCREEN_NAME_ENUM.CHOOSE_METAMASK_ACCOUNT
              );
            }
          }

          break;
        case "wallet-connect":
          EventBus.$emit(MIX_PANEL_EVENT.PAYMENT_METHOD_SELECTED, { slug });
          this.initializeWalletConnect();
          break;
        case "coinbase":
          EventBus.$emit(MIX_PANEL_EVENT.PAYMENT_METHOD_SELECTED, { slug });
          this.$refs.onRampUrlRef.click();
          this[acts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.PROCESSING);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>