<template>
    <div class="flex flex-col flex-1 pt-8 px-4 lg:px-8">
        <h6 class="w-9/12 mx-auto text-center uppercase text-sm md:text-lg font-medium mb-8">Use any of our test options to complete  this payment</h6>
        <div class="space-y-4">
            <ListItem coin="success" title="Successful" description="Simulate successful payment" @selected="selected" />
            <ListItem coin="overpaid" title="Overpaid" description="Simulate overpayment"  @selected="selected" />
            <ListItem coin="underpaid" title="Underpaid" description="Simulate underpayment"  @selected="selected" />
            <!-- <ListItem coin="failed" title="Failed" description="Description Caption"  @selected="selectCoin" /> -->
        </div>
        <button class="plain mt-auto" @click="useAddressMode">Use Address Mode</button>
    </div>
</template>

<script>
import ListItem from '@/components/Payments/ListItem.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import * as gets from '@/store/getters.types'
import * as muts from '@/store/mutations.types'
import * as acts from "@/store/action.types"
import { SCREEN_NAME_ENUM } from '@/services/constants'

export default {
    name: 'TestSimulation',
    components: {
        ListItem
    },
    computed: {
        ...mapGetters([gets.TRANSACTION])
    },
    methods: {
        ...mapMutations([muts.SET_IS_PROCESSING_SIMULATION]),
        ...mapActions([acts.SET_CURRENT_ACTIVE_SCREEN]),
        async selected(title) {
            let type;
            switch (title) {
                case 'Successful':
                    type = 'success'
                    break;
                case 'Overpaid':
                    type = 'overpaid'
                    break;

                case 'Underpaid':
                    type = 'underpaid'
                    break;
            
                default:
                    break;
            }

            try {
                const { query } = this.$route
                const {api_key} = query
                const { data } = await this.$api.simulateTransfer(api_key, this.transaction.reference, type)
                const {message} = data;
                this.$toast.success(message);
                this[muts.SET_IS_PROCESSING_SIMULATION](true)
                this[acts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.PROCESSING)
            } catch(error){
                const { message } = this.$utils.getAxiosErrorResponse(error);
                this.$toast.error(message);
                this.$catchError(error);
            }

        },
        useAddressMode(){
            this[acts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.CHOOSE_COIN)
        }
    },
}
</script>

<style lang="scss" scoped>

</style>