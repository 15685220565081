/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */

import * as muts from "@/store/mutations.types"
import * as acts from "@/store/action.types"
import { mapMutations, mapActions } from 'vuex'
import { CHAINS, INFURA_ID, SCREEN_NAME_ENUM } from "@/services/constants";
import WalletConnectProvider from "@walletconnect/web3-provider";

export default {
  name: "WalletConnect",
  data() {
    return {};
  },
  methods: {
    ...mapActions([acts.SET_CURRENT_ACTIVE_SCREEN]),
    ...mapMutations([muts.SET_METAMASK_ACCOUNT]),
    async initializeWalletConnect() {

      try {
        const connector = new WalletConnectProvider({
          infuraId: INFURA_ID,
        });
        await connector.enable(); //open modal 

        this.$emit('wallet-connector', connector)
        this.handleAccountChange(connector.accounts, connector.chainId)
        

        connector.on("disconnect", (error, payload) => {
          this[muts.SET_METAMASK_ACCOUNT](null)
          this[acts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.DASHBOARD)
        });
      } catch(error){
        // console.log(error)
      }

    },
    handleAccountChange(accounts, chainId){
        if(!CHAINS.includes(chainId)) {
            this.$toast.info('Please switch to Ethereum Mainnet')
            return;
        }
        this[muts.SET_METAMASK_ACCOUNT](accounts[0])
        this[acts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.WALLET_CONNECT)
    }
  },
};
