<template>
    <div class="relative">
        <search-icon class="absolute left-5 top-4"/>
        <input v-bind="$attrs" class="pl-14 py-5" v-model.trim="inputValue" placeholder="Try 'Bitcoin' or 'ETH'"/>
    </div>
</template>

<script>
import SearchIcon from '../Icons/SearchIcon.vue';

    export default {
        name: 'SearchInput',
        components: {
            SearchIcon
        },
        computed: {
            inputValue: {
                get() {
                return this.value;
                },
                set(value) {
                this.$emit("input", value);
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>