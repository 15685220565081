import * as HtmlEntities from 'he'

export default () => ({
  getIconName(slug) {
    switch (slug.toLowerCase()) {
      case "transfer":
        return "Wallets";
      case "coinbase":
        return "Coinbase";
      case "wallet-connect":
        return "WalletConnet";
      case "fluidcoins":
        return "Fluidcoins";
      case 'metamask':
        return 'MetaMask';
      default:
        break;
    }
  },
  getAxiosErrorResponse: function(error) {
    // err.response is set for axios errors
    if (error.response && error.response.data) {
      return error.response.data;
    }

    return { message: error.message, data: undefined };
  },
  postMessage(event, referer){
    if(window && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(event))
      return;
    }

    if(referer){
      window.parent.postMessage(event, referer);
      
    }

    
    
  },
  moniker(name){
    const nameArray = name.split(' ')
    if(nameArray.length > 1) {
      return `${nameArray[0].charAt(0)} ${nameArray[1].charAt(0)}`
    } else {
      return name.charAt(0)
    }
  },
  debounce: function (fn, delay) {
    let timer;
    return function () {
    let context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
            fn.apply(context, args);
        }, delay)
    }
  },
  getCurrencyPrefix(currency = 'NGN'){
    switch (currency.toUpperCase()) {
      case 'USD':
        return '$'
    
      default:
        return '₦';
    }
  },
  isMobile(){
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  },
  decodeHTMLEntities(stringValue){
    return HtmlEntities.decode(stringValue)
  },
  getCoinToSendTo(code, network){
    if(network){
      switch (network) {
        case 'TRC20':
        case 'POLYGON':
          return 'USDT or USDC'
        case 'ERC20':
            return 'DAI, USDT or USDC'
        default:
        return code;
      }
    } else {
      return code
    }
  }

});
