export default ($axios) => ({
  widget: (payload) => {
    return $axios.post("/widget", payload);
  },
  getRelayData(payment_link_id){
    return $axios.get(`/relay/links/${payment_link_id}`)
  },
  getTransactionStatus(api_key, reference) {
    return $axios.get(`/status/transaction/${reference}?public_key=${api_key}`);
  },
  simulateTransfer(api_key, reference, type) {
    return $axios.put(`/widget?public_key=${api_key}`, {
      reference,
      type,
    });
  },
});
