<template>
  <div>
    <div class="flex items-center flex-col" v-if="!is_relay_mode">
      <p class="text-white text-base tracking-widest font-medium">AMOUNT</p>
      <h2
        class="
          text-5xl
          md:text-6xl
          font-semibold
          text--primary-gradient
          tracking-wide
        "
      >
        {{ amount | currencyFormat(0, fiat_currency) }}
      </h2>
    </div>
    <template v-if="canShowMerchant">
      <div class="flex justify-between items-center mt-4">
        <p class="text-base text-white uppercase font-medium">{{!is_relay_mode ? 'Merchant' : 'Receiver'}}</p>
        <p class="text-base text-white uppercase font-medium">fees</p>
      </div>
      <div class="grid grid-cols-8 justify-between gap-x-4 mt-2">
        <template>
          <div class="col-span-5 flex items-center" v-if="merchantName">
            <div
              class="
                w-8
                h-8
                rounded-full
                flex
                justify-center
                items-center
                flex-none
              "
              :class="{'gradient-primary': !merchantLogo}"
            >
              <template v-if="!merchantLogo">
                <span class="text-xs font-medium uppercase"> {{ $utils.moniker(merchantName) }}</span>
              </template>
              <template v-else>
              <img :src="merchantLogo" :alt="merchantName" class="w-full h-full object-contain rounded-full" />
              </template>
            </div>
            <p class="ml-2 text-base text-white font-semibold truncate" >
              {{$utils.decodeHTMLEntities(merchantName) }}
            </p>
          </div>
          <div v-else class="col-span-5 skeleton w-24 h-4"></div>
        </template>

        <template>
          <div class="col-span-3 ">
            <p class="text-base text-white text-right uppercase font-semibold" v-if="merchantName">{{ fees | currencyFormat(2, fiat_currency)}}</p>
            <div v-else class="skeleton w-24 h-4 ml-auto"></div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import * as gets from "@/store/getters.types"
import { mapGetters} from 'vuex'
import { SCREEN_NAME_ENUM } from '@/services/constants';
export default {
  name: "QuickSummaryWithMerchant",
  data() {
    return {
      queryParams: {}
    }
  },
  mounted () {
    const {query} = this.$route;
    this.queryParams = query
  },
  computed: {
    ...mapGetters([gets.TRANSACTION, gets.CURRENT_ACTIVE_SCREEN, gets.IS_RELAY_MODE, gets.RELAY_DATA, gets.FIAT_CURRENCY]),
    canShowMerchant(){
      return this.current_active_screen === SCREEN_NAME_ENUM.DASHBOARD || this.current_active_screen === SCREEN_NAME_ENUM.ERROR
    },
    amount(){
      if(this.transaction.amount) return this.transaction.amount
      
      if(this.queryParams && this.queryParams.amount) return this.queryParams.amount

      return 0
    },
    fees(){
      if(this.is_relay_mode) return  10000

      else if(this.transaction.fee) return this.transaction.fee

      else if(this.amount) return (this.amount) * 0.01

      else return 0
    },
    merchantName(){
      if(this.is_relay_mode) return this.relay_data.human_readable_name
      else if(this.transaction.merchant) return this.transaction.merchant.name
      else return ''
    },
    merchantLogo(){
      return (this.transaction.merchant && this.transaction.merchant.logo) || false
    }
  },
};
</script>

<style lang="scss" scoped></style>
