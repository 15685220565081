<template>
  <button v-bind="$attrs" @click="$emit('click')">
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9997 2.3335C7.57134 2.3335 2.33301 7.57183 2.33301 14.0002C2.33301 20.4285 7.57134 25.6668 13.9997 25.6668C20.428 25.6668 25.6663 20.4285 25.6663 14.0002C25.6663 7.57183 20.428 2.3335 13.9997 2.3335ZM17.9197 16.6835C18.258 17.0218 18.258 17.5818 17.9197 17.9202C17.7447 18.0952 17.523 18.1768 17.3013 18.1768C17.0797 18.1768 16.858 18.0952 16.683 17.9202L13.9997 15.2368L11.3163 17.9202C11.1413 18.0952 10.9197 18.1768 10.698 18.1768C10.4763 18.1768 10.2547 18.0952 10.0797 17.9202C9.74134 17.5818 9.74134 17.0218 10.0797 16.6835L12.763 14.0002L10.0797 11.3168C9.74134 10.9785 9.74134 10.4185 10.0797 10.0802C10.418 9.74183 10.978 9.74183 11.3163 10.0802L13.9997 12.7635L16.683 10.0802C17.0213 9.74183 17.5813 9.74183 17.9197 10.0802C18.258 10.4185 18.258 10.9785 17.9197 11.3168L15.2363 14.0002L17.9197 16.6835Z"
        fill="black"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "CloseIcon",
};
</script>

<style lang="scss" scoped>
</style>