<template>
    <div class="px-4 md:px-8 pt-8" :class="{'pb-8': !isTestMode}">
        <div class="flex justify-between">
            <div>
                <h6 class="font-medium text-base md:text-lg">Showing only Stable coins</h6>
                <p class="text-sm">Toggle to {{  onlyStableCoin ? `show all coins`: 'show only stable coins'}}</p>
            </div>
            <Toggle @input="filterStableCoinsOnly" :value="onlyStableCoin" :disabled="isTestMode" />
        </div>
        <div class="mb-6 mt-6">
            <SearchInput @input="attemptSearch($event)" />
        </div>
        
        <div class="space-y-4">
            <p class="w-9/12 mx-auto text-grey400 my-40" v-if="!sortedCoins.length">Ouch! 😢 Looks like we don't have that coin yet. <a href="https://airtable.com/shrdCzCntmLnFlfgU" aria-label="form" rel="noreferrer noopener" target="_blank" class="underline">Tell us here</a> so we can add it soon.</p>
            <CoinSelectItem :logoUrl="coin.logo || coin.image" :title="coin.code" @selected="selectCoin" v-for="coin in sortedCoins" :key="coin.code" />
        </div>
        <button class="plain mt-3" @click="gotoSimulation" v-if="!is_relay_mode && isTestMode">Simulate Payment</button>
        <Modal v-if="showNetworkModal" @close="showNetworkModal = false">
            <div class="bg-white w-10/12 md:w-9/12 mx-auto rounded-medium px-4 md:px-8 py-6 md:py-8" @click.stop>
                <p class="text-base text-black text-center font-medium">SELECT NETWORK</p>
                <form class="mt-9 w-full" @submit.prevent="submit">
                    <select class="font-medium" v-model="selectedNetworkName">
                        <option value="">Choose Network</option>
                        <option v-for="network in selectedCoin.networks" :key="network.name" :value="network.name">{{ network.name }}</option>
                    </select>
                    <div role="button" tabindex="0" aria-labelledby="Information" class="flex items-center mt-6" @click="showNetworkInfoModal = true">
                        <information/>
                        <p class="text-sx md:text-sm ml-2 font-medium">Why Select A Network?</p>
                    </div>
                    <button type="submit" aria-label="Continue" class=" mt-7 " :disabled="!selectedNetworkName" :class="{'primary': selectedNetworkName}"><span class="font-medium text-sm md:text-xl" :class="{'text--primary-gradient': !selectedNetworkName, 'text-black': selectedNetworkName}">CONTINUE</span></button>
                </form>
            </div>
        </Modal>
        <Modal v-if="showNetworkInfoModal" @close="showNetworkInfoModal = false">
            <div class="bg-white w-10/12 md:w-9/12 mx-auto rounded-medium pt-8 overflow-hidden" @click.stop>
                <div class="px-4 md:px-8 mb-9">
                    <p class="text-sm md:text-base text-black text-center font-medium">WHAT IS A NETWORK?</p>
                    <p class="text-xs md:text-lg font-medium text-grey400 text-center mt-7 tracking-wider">Some cryptocurrencies may be built on more than one network. It is important to select the right one to avoid loss of your coins. For cryptocurrencies with only one network, the default is automatically selected. </p>
                </div>
                <button class="text-base text-black bg-grey100 py-7 text-center font-semibold tracking-wider w-full" @click="showNetworkInfoModal = false">OK, GOT IT</button>
            </div>
        </Modal>
    </div>
</template>

<script>
import CoinSelectItem from '@/components/Payments/ListItem.vue'
import Toggle from '@/components/Input/Toggle.vue'
import SearchInput from '@/components/Input/SearchInput.vue'
import Modal from '@/components/Modal/index.vue'


import * as gets from "@/store/getters.types"
import * as muts from '@/store/mutations.types'
import * as acts from "@/store/action.types"
import { mapGetters, mapMutations, mapActions} from 'vuex'
import { MIX_PANEL_EVENT, SCREEN_NAME_ENUM } from '@/services/constants'
import CommonMixin from '@/mixins/common'
import {EventBus} from '@/services/event-bus'
import Information from '../Icons/Information.vue'

    export default {
        name: 'CoinSelect',
        mixins: [CommonMixin],
        components: {
            CoinSelectItem,
            Toggle,
            SearchInput,
            Modal,
            Information
        },
        data() {
            return {
                showNetworkModal: false,
                showNetworkInfoModal: false,
                selectedNetworkName: null,
                selectedCoin: {},
                networks: [],
                searchParam: '',
                sortedCoins: [],
                onlyStableCoin: false,
                
            }
        },
        computed: {
            ...mapGetters([gets.COINS, gets.IS_RELAY_MODE])
        },
        mounted () {
            this.sortedCoins = this.coins;
            this.onlyStableCoin = this.is_relay_mode
        },
        methods: {
            ...mapMutations([muts.SET_SELECTED_COIN, muts.SET_SELECTED_NETWORK, muts.SET_IS_PROCESSING_SIMULATION]),
            ...mapActions([acts.SET_CURRENT_ACTIVE_SCREEN]),
            selectCoin(code) {
                this.selectedCoin = this.coins.find( coin => coin.code === code)
                const {networks}  = this.selectedCoin
                if(networks.length === 1){
                    this.selectedNetworkName = networks[0].name
                } else {
                    this.selectedNetworkName = ''
                }
                this.networks = networks
                this.showNetworkModal = true
            },
            submit(){
                
                if(!this.selectedNetworkName){
                    return
                }
                this[muts.SET_SELECTED_COIN](this.selectedCoin)
                this[muts.SET_SELECTED_NETWORK](this.networks.find( network => network.name === this.selectedNetworkName))
                this[muts.SET_IS_PROCESSING_SIMULATION](false)
                this[acts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.COIN_ADDRESS)
                this.showNetworkModal = false
                EventBus.$emit(MIX_PANEL_EVENT.SELECTED_COIN, {code: this.selectedCoin.code , network: this.selectedNetworkName})
            },
            gotoSimulation(){
                this[acts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.SIMULATE)
            },
            filterStableCoinsOnly(onlyStableCoin){
                this.onlyStableCoin = onlyStableCoin
                if(onlyStableCoin) {
                    this.sortedCoins =  this.coins.filter( coins => coins.is_stable_coin)
                } else {
                    this.search(this.searchParam)
                }
                
            },
            attemptSearch(searchTerm){
                this.$utils.debounce(this.search, 600)(searchTerm)
            },
            search(searchTerm){
                this.sortedCoins = this.coins.filter( coin => coin.human_readable_name.toLowerCase().includes(searchTerm.toLowerCase()) || coin.code.toLowerCase().includes(searchTerm.toLowerCase()))
                this.searchParam = searchTerm
                if(this.sortedCoins.length === 0){
                    EventBus.$emit(MIX_PANEL_EVENT.UNAVAILABLE_COIN, {coin: searchTerm})
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>