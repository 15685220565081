import Vue from 'vue'
import Utils from '../services/utils'

const utils = Utils()
Vue.filter('currencyFormat', (amountInKobo, minimumFractionDigits = 0, currency = 'NGN') => {
    let amount = 0;
  
    if (amountInKobo > 0) {
      amount = amountInKobo / 100;
    }
    const formattedAmount = new Intl.NumberFormat('en-US', {
      minimumFractionDigits
    }).format(amount);

    const PREFIX = utils.getCurrencyPrefix(currency || 'NGN')
    return `${PREFIX}${formattedAmount}`
  });

  Vue.filter('shortenAddress', function(address, leftCount = 6, rightCount = 5){
    if(address) {
      if (address.length > leftCount && address.length > rightCount) {
        return `${address.substring(0, leftCount)}...${address.substring(
          address.length - rightCount
        )}`;
      } else {
        return address
      }
    }
  
    return 'Not available';
  })  
  // Vue.filter('nairaString', (amountInKobo) => {
  //   let amount = 0;
  
  //   if (amountInKobo > 0) {
  //     amount = amountInKobo / 100;
  //   }
  
  //   return `N ${amount.toFixed(2)}`
  // });