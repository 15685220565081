<template>
    <div class="h-full flex flex-col justify-center items-center w-9/12 mx-auto pt-20">
        <h5 class="text-center font-medium text-2xl">{{errorMessage}}</h5>
        <button class="secondary mt-10">
            <span class="text--primary-gradient" @click="closeApp">Try Again</span>
        </button>
    </div>
</template>

<script>
import * as gets from '@/store/getters.types'
import {mapGetters} from 'vuex'
import { EventBus } from '@/services/event-bus'
import { Events } from '@/services/constants'

    export default {
        name: 'ErrorBoundary',
        computed:{
            ...mapGetters([gets.ERROR_MESSAGE])
        },
        methods: {
            closeApp(){
                EventBus.$emit(Events.CLOSE_APP)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>