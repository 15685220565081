<template>
    <div class="px-6 md:px-8 pt-4 flex-1">
        <div class="w-40 h-40 md:w-80 md:h-80 mx-auto">
           <img class="w-full h-full object-cover" alt="loading" src="https://res.cloudinary.com/fluidcoins/image/upload/v1638873877/widget/9151-loading-spinner_ept32o.gif" />
        </div>
        <div>
            <h6 class="text-xl md:text-2xl text-black font-medium text-center">Transaction Processing</h6>
            <p class="text-grey400 text-center text-base mt-3">Your transaction is processing. We will notify you as soon as it's confirmed. Cheers</p>
        </div>
        <button class="secondary mt-24">
            <span class="text--primary-gradient font-medium" @click="attemptClose">Okay</span>
        </button>
        <p class="text-black text-center mt-7 text-base font-medium tracking-wide" v-if="!is_relay_mode">Link will expire in {{ timer }}</p>
    </div>
</template>

<script>
    import * as gets from '@/store/getters.types'
    import {mapGetters} from 'vuex'
import { EventBus } from '@/services/event-bus'
import { Events } from '@/services/constants'
    export default {
        name: 'Processing',
        components: {
        },
        data() {
            return {
            }
        },
        mounted () {
        },
        computed: {
            ...mapGetters([gets.TIMER, gets.IS_RELAY_MODE])
        },
        methods: {
            attemptClose() {
                if(this.is_relay_mode){
                    EventBus.$emit(Events.CLOSE_APP)
                }
                
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>