<template>
    <div class="flex items-center justify-center pt-6 mt-auto min-h-4">
        <p class="text-base text-black font-medium mr-4">Secured By</p>
        <fluidcoins class="w-8 h-8" />
    </div>
</template>

<script>
import Fluidcoins from '../Icons/Fluidcoins.vue'
    export default {
        name: 'SecuredBy',
        components: {
            Fluidcoins
        },
    }
</script>

<style lang="scss" scoped>

</style>