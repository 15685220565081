import * as gets from "@/store/getters.types"
import * as acts from "@/store/action.types"
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'MetaMaskMixin',
    computed: {
        ...mapGetters([gets.METAMASK_ACCOUNT, gets.COINS, gets.TIMER, gets.IS_RELAY_MODE]),
        sortedCoins(){
            return this.coins.filter( coin => coin.is_stable_coin && coin.code !== 'BUSD')
        }
    },
    methods: {
        ...mapActions([acts.SET_CURRENT_ACTIVE_SCREEN]),
    },
}