import {EventBus} from '@/services/event-bus'
import { MIX_PANEL_EVENT } from '@/services/constants'

export default  {
    name: 'MixPanelEventMixin',
    mounted () {
        EventBus.$on(MIX_PANEL_EVENT.PAYMENT_METHOD_CLICKED, (payload) => {
            this.$mixpanel.track(MIX_PANEL_EVENT.PAYMENT_METHOD_CLICKED, payload)
        })
        
        EventBus.$on(MIX_PANEL_EVENT.PAYMENT_METHOD_SELECTED, (payload) => {
            this.$mixpanel.track(MIX_PANEL_EVENT.PAYMENT_METHOD_SELECTED, payload)
        })

        EventBus.$on(MIX_PANEL_EVENT.UNAVAILABLE_COIN, (payload) => {
            this.$mixpanel.track(MIX_PANEL_EVENT.UNAVAILABLE_COIN, payload)
        })

        EventBus.$on(MIX_PANEL_EVENT.COPY_ADDRESS, (payload) => {
            this.$mixpanel.track(MIX_PANEL_EVENT.COPY_ADDRESS, payload)
        })

        EventBus.$on(MIX_PANEL_EVENT.COPY_MEMO, (payload) => {
            this.$mixpanel.track(MIX_PANEL_EVENT.COPY_MEMO, payload)
        })

        EventBus.$on(MIX_PANEL_EVENT.COPY_DESTINATION_TAG, (payload) => {
            this.$mixpanel.track(MIX_PANEL_EVENT.COPY_DESTINATION_TAG, payload)
        })

        EventBus.$on(MIX_PANEL_EVENT.SELECTED_COIN, (payload) => {
            this.$mixpanel.track(MIX_PANEL_EVENT.SELECTED_COIN, payload)
        })

        EventBus.$on(MIX_PANEL_EVENT.CHANGED_COIN, (payload) => {
            this.$mixpanel.track(MIX_PANEL_EVENT.CHANGED_COIN, payload)
        })

        EventBus.$on(MIX_PANEL_EVENT.CLOSE_WIDGET, (payload) => {
            this.$mixpanel.track(MIX_PANEL_EVENT.CLOSE_WIDGET, payload)
        })
    },

    destroyed() {
        EventBus.$off()
    },
}