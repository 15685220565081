<template>
  <div>
    <div class="flex items-center flex-col" v-if="!is_relay_mode">
    <p class="text-white text-base tracking-widest font-medium">AMOUNT</p>
    <h2 class="text-4xl md:text-5xl font-semibold text-white tracking-wide">
      {{ transaction.amount | currencyFormat(0, fiat_currency) }}
    </h2>
  </div>
  <div class="mt-1">
    <p class="text-xl font-medium text-center text--primary-gradient">
      {{ selectedCoin.human_readable_amount}} {{ selectedCoin.human_readable_name }}
    </p>
  </div>
  </div>
</template>

<script>
import * as gets from "@/store/getters.types"
import { mapGetters} from 'vuex'

export default {
  name: 'QuickSummary',
  computed: {
    ...mapGetters([gets.SELECTED_COIN, gets.TRANSACTION, gets.IS_RELAY_MODE, gets.FIAT_CURRENCY]),
  },
};
</script>

<style lang="scss" scoped>
</style>