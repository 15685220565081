<template>
    <div class="px-4 md:px-8 pt-8 h-full flex flex-col" v-if="fetchApiState === API_STATE_ENUM.RESOLVED">
        <div class="flex items-center justify-center">
            <button class="border border-gainsboro rounded-medium px-8 py-2" role="button" @click="showAddressModal = true" aria-label="show connected address">
                <p class="text-grey400 text-sm font-medium text-center">Connected Wallet</p>
                <h6 class="text-xl font-semibold mt-1  text-center">{{ metamask_account | shortenAddress(7,4)}}</h6>
            </button>
        </div>
        <p class="text-base text-black text-center font-medium mt-8">SELECT COIN</p>

        <div class="space-y-4 mt-10 mb-7">
            <p class="w-9/12 mx-auto text-grey400 my-40" v-if="!sortedCoins.length">Ouch! 😢 Looks like we don't have that coin yet. <a href="https://airtable.com/shrdCzCntmLnFlfgU" aria-label="form" rel="noreferrer noopener" target="_blank" class="underline">Tell us here</a> so we can add it soon.</p>
            <CoinSelectItem :logoUrl="coin.logo || coin.image" :title="coin.code" @selected="handleSelect(coin)" v-for="coin in sortedCoins" :key="coin.code" hasAmount :amount="formatBalance(coin.code, balances[coin.code])" />
        </div>
        <p class="text-black text-center mt-auto text-base font-medium tracking-wide" v-if="!is_relay_mode">Link will expire in {{ timer }}</p>
        <Modal v-if="showAddressModal" @close="showNetworkInfoModal = false">
            <div class="bg-white w-10/12 md:w-9/12 mx-auto rounded-medium pt-8 overflow-hidden" @click.stop>
                <div class="px-4 md:px-8 mb-9">
                    <p class="text-sm md:text-base text-black text-center font-medium">CONNECTED ADDRESS</p>
                    <p class="text-xs md:text-lg font-medium text-grey400  mt-7 tracking-wider break-all">{{ metamask_account }}</p>
                </div>
                <div class="flex justify-center mb-7">
                    <button @click="copy(metamask_account)" aria-label="copy address" class="bg-navyBlue text-white px-4 py-2 text-sm self-center mx-auto rounded-medium">COPY ADDRESS</button>
                </div>
                <button class="text-base text-black bg-grey100 py-7 text-center font-semibold tracking-wider w-full" @click="showAddressModal = false">CLOSE</button>
            </div>
        </Modal>
    </div>
    <div class="flex flex-col items-center justify-center w-full h-full" v-else>
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <p class="text-lg font-medium text-black">Just a Moment</p>
    </div>
</template>


<script>

import * as acts from "@/store/action.types"
import CoinSelectItem from '@/components/Payments/ListItem.vue'
import Modal from '@/components/Modal/index.vue'
import CopyMixin from '@/mixins/copy-to-clipboard.js'
import {ethers} from "ethers";
import { ER20_ABI, TOKEN_ADDRESSES, API_STATE_ENUM, SCREEN_NAME_ENUM, WEI_UNITS } from '@/services/constants'
import MetaMaskMixin from '@/mixins/meta-mask.js'

    export default {
        name: 'MetamaskScreen',
        mixins: [CopyMixin, MetaMaskMixin],
        components: {
            CoinSelectItem,
            Modal,
        },
        data() {
            return {
                API_STATE_ENUM,
                fetchApiState: API_STATE_ENUM.PENDING,
                showAddressModal: false,
                accountBalance: null,
                balances: {},
                tokenAddresses: {},
            }
        },
        async mounted () {
            this.getBalances()
        },
        methods: {
            async handleSelect(coin){
                try {
                    const {networks , amount, code, human_readable_amount} = coin
                    const erc20Network =  networks.find( network => network.name === 'ERC20')

                    if(erc20Network){

                        if(ethers.BigNumber.from(this.balances[code]).gt(amount)){

                            const provider = new ethers.providers.Web3Provider(window.ethereum);
                            const signer =  provider.getSigner(this.metamask_account)
                            const contract = new ethers.Contract(this.tokenAddresses[code], ER20_ABI, signer)
                            const formattedAmount = ethers.utils.parseUnits(human_readable_amount, WEI_UNITS[code])
                            contract.transfer(erc20Network.address,formattedAmount)
                            .then(() => {
                                this[acts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.PROCESSING)
                            })
                            .catch( () => {
                                this.$toast.error('You rejected the request. Kindly select coin to proceed.')
                            })
                            

                            
                        } else {
                            this.$toast.info('Insufficient amount')
                        }
                        
                    }
                } catch(error){
                    this.$catchError(error);
                }
            },
            async getBalances() {
                this.fetchApiState = API_STATE_ENUM.PENDING
                try {
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    this.accountBalance = await provider.getBalance(this.metamask_account)

                    const balances = {}
                    const transferPromises = {}
                    
                    for (const address of TOKEN_ADDRESSES) {
                        this.tokenAddresses[address.token] = address.address
                        const contract = new ethers.Contract(address.address, ER20_ABI, provider)
                        const balance  = await contract.balanceOf(this.metamask_account)
                        balances[address.token] =  balance
                    }

                    this.balances = balances
                    this.transferPromises = transferPromises
                    this.fetchApiState = API_STATE_ENUM.RESOLVED
                } catch(error) {
                    this[acts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.DASHBOARD)
                    this.fetchApiState = API_STATE_ENUM.REJECTED
                    this.$toast.error('Unable to fetch balances')
                    this.$catchError(error);
                }
            },
            formatBalance(coin, balance){
                return ethers.utils.formatUnits(balance, WEI_UNITS[coin])
            }
        },
        
    
    }
</script>

<style lang="css" scoped>
    .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

</style>