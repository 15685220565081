<template>
  <label class="switch" >
    <input type="checkbox" v-model="inputValue" :value="value" v-bind="$attrs" :class="{'cursor-not-allowed':true}" />
    <span class="slider round" :class="{'cursor-not-allowed': $attrs.disabled, 'cursor-pointer': !$attrs.disabled}"></span>
  </label>
</template>

<script>
export default {
  name: "Toggle",
  props: {
      value: {
        type: Boolean,
        default: false
      },
    },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="css" scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 3.5rem;
  height: 2.1rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  /* cursor: pointer; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fafafa;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.8rem;
  width: 1.8rem;
  left: 0.4rem;
  bottom: 0.16rem;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
}

input:checked + .slider {
  background-color: #34c759;
}

input:focus + .slider {
  box-shadow: 0 0 1px #34c759;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1rem);
  -ms-transform: translateX(1rem);
  transform: translateX(1rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 1.5rem;
}

.slider.round:before {
  border-radius: 50%;
}
</style>