import Vue from 'vue'
import Vuex from 'vuex'

import * as gets from './getters.types'
import * as muts from './mutations.types'
import * as acts from './action.types'
import { SCREEN_NAME_ENUM } from '@/services/constants'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedPaymentOption: '',
    paymentOptionSelected: false,
    showCancelModal: false,
    coins: [],
    paymentMethods: [],
    transaction: {},
    stepper: 0,
    strokeDashoffset: 0,
    timerColor: '#035AA6',
    time: '',
    startCountdown: false,
    startTransaction: false,
    failureTransactionContent: {},
    transactionStatusPayload: {},
    isRelayMode: false,
    relay_data: {},
    showFeesSheet: false,
    showRateSheet: false,
    selectedCoin: {},
    usePayWithTransferTestMode: false,
    errorMessage: '',

    currentActiveScreen: SCREEN_NAME_ENUM.DASHBOARD,
    previousScreen: SCREEN_NAME_ENUM.DASHBOARD,
    selectedNetwork: {},
    sessionHasExpired: false,
    isProcessingSimulation: false,
    fiatCurrency: 'NGN',
    metamaskAccount: null
  },
  getters: {
    hasSelectedPaymentOption: state => state.paymentOptionSelected,
    getSelectedPaymentOption: state => state.selectedPaymentOption,
    [gets.CAN_SHOW_CLOSE_MODAL]: state => state.showCancelModal,
    [gets.COINS](state){
      return state.coins
    },
    [gets.TRANSACTION](state){
      return state.transaction
    },
    [gets.PAYMENT_METHODS](state){
      return state.paymentMethods
    },
    [gets.STEPPER_COUNT](state){
      return state.stepper
    },
    [gets.TIMER](state){
      return state.time
    },
    [gets.TIMER_COLOR](state){
      return state.timerColor
    },
    [gets.DASH_STROKE](state){
      return state.strokeDashoffset
    },
    [gets.START_COUNTDOWN](state){
      return state.startCountdown
    },
    [gets.START_TRANSACTION](state){
      return state.startTransaction
    },
    [gets.TRANSACTION_FAILURE_CONTENT](state){
      return state.failureTransactionContent
    },
    [gets.IS_RELAY_MODE](state){
      return state.isRelayMode
    },
    [gets.RELAY_DATA](state){
      return state.relay_data
    },
    [gets.CAN_VIEW_FEES_SHEET](state){
      return state.showFeesSheet
    },
    [gets.TRANSACTION_STATUS_PAYLOAD](state){
      return state.transactionStatusPayload
    },
    [gets.CAN_VIEW_RATE_SHEET](state){
      return state.showRateSheet
    },
    [gets.SELECTED_COIN](state){
      return state.selectedCoin
    },
    [gets.USE_PAY_WITH_TRANSFER_TEST_MODE](state){
      return state.usePayWithTransferTestMode
    },
    [gets.ERROR_MESSAGE](state){
      return state.errorMessage
    },
    [gets.HAS_ERROR](state){
      return state.errorMessage.length > 0
    },
    [gets.CURRENT_ACTIVE_SCREEN](state){
      return state.currentActiveScreen
    },
    [gets.SELECTED_NETWORK](state){
      return state.selectedNetwork
    },
    [gets.SESSION_HAS_EXPIRED](state){
      return state.sessionHasExpired
    },
    [gets.IS_PROCESSING_SIMULATION](state){
      return state.isProcessingSimulation
    },
    [gets.FIAT_CURRENCY](state){
      return state.fiatCurrency
    },
    [gets.METAMASK_ACCOUNT](state){
      return state.metamaskAccount
    },
    [gets.PREVIOUS_SCREEN](state){
      return state.previousScreen
    }
  },
  mutations: {
    selectPaymentOption(state, paymentOption){
      Vue.set(state, 'selectedPaymentOption', paymentOption)
      Vue.set(state, 'paymentOptionSelected', true)
      // state.hasSelectedPaymentOption = hasSelectedPaymentOption
    },
    goBack(state){
      state.paymentOptionSelected = false
    },
    [muts.SET_CAN_SHOW_CANCEL_MODAL](state, value) {
      state.showCancelModal = value
    },
    [muts.SET_COINS](state, coins){
      state.coins = coins
    },
    [muts.SET_PAYMENT_METHOD](state, payment_method){
      state.paymentMethods = payment_method
    },
    [muts.SET_TRANSACTION](state, transaction){
      state.transaction = transaction
    },
    [muts.SET_STEPPER](state, count){
      state.stepper = count
    },
    [muts.SET_DASH_STROKE](state, strokeDashoffset) {
      state.strokeDashoffset = strokeDashoffset
    },
    [muts.SET_TIMER_COLOR](state, color){
      state.timerColor = color
    },
    [muts.SET_TIMER](state, time){
      state.time = time
    },
    [muts.SET_START_COUNTDOWN](state, start){
      state.startCountdown = start
    },
    [muts.SET_START_TRANSACTION](state, start){
      state.startTransaction = start
    },
    [muts.SET_TRANSACTION_FAILURE_CONTENT](state, content){
      state.failureTransactionContent = content
    },
    [muts.SET_RELAY_MODE](state, isRelay){
      state.isRelayMode = isRelay
    },
    [muts.SET_RELAY_DATA](state, relay){
      state.relay_data = relay
    },
    [muts.SET_CAN_VIEW_FEES_SHEET](state, value) {
      state.showFeesSheet = value
    },
    [muts.SET_TRANSACTION_STATUS_PAYLOAD](state, payload){
      state.transactionStatusPayload = payload
    },
    [muts.SET_CAN_VIEW_RATE_SHEET](state, value){
      state.showRateSheet = value
    },
    [muts.SET_SELECTED_COIN](state, coin){
      state.selectedCoin = coin
    },
    [muts.SET_USE_PAY_WITH_TRANSFER_TEST_MODE](state, value){
      state.usePayWithTransferTestMode = value
    },
    [muts.SET_ERROR_MESSAGE](state, errorMessage){
      state.errorMessage = errorMessage
    },
    [muts.SET_CURRENT_ACTIVE_SCREEN](state, screen){
      state.currentActiveScreen = screen
    },
    [muts.SET_SELECTED_NETWORK](state, network){
      state.selectedNetwork = network
    },
    [muts.SET_SESSION_HAS_EXPIRED](state, sessionExpired){
      state.sessionHasExpired = sessionExpired
    },
    [muts.SET_IS_PROCESSING_SIMULATION](state, isProcessingSimulation) {
      state.isProcessingSimulation = isProcessingSimulation
    },
    [muts.SET_FIAT_CURRENCY](state, currency){
      state.fiatCurrency = currency
    },
    [muts.SET_METAMASK_ACCOUNT](state, metamaskAccount) {
      state.metamaskAccount = metamaskAccount
    },
    [muts.SET_PREVIOUS_SCREEN](state, previousScreen) {
      state.previousScreen = previousScreen
    }
  },
  actions: {
    [acts.INCREASE_STEPPER]({commit, state}){
      commit(muts.SET_STEPPER, state.stepper += 1 )
    },
    [acts.DOUBLE_INCREASE_STEPPER]({commit, state}){
      commit(muts.SET_STEPPER, state.stepper += 2 )
    },
    [acts.DECREASE_STEPPER]({commit, state}){
      commit(muts.SET_STEPPER, state.stepper = 0 )
    },
    [acts.REGISTER_ERROR]({commit, state}, errorMessage){
      commit(muts.SET_ERROR_MESSAGE, state.errorMessage = errorMessage)
      commit(muts.SET_CURRENT_ACTIVE_SCREEN, SCREEN_NAME_ENUM.ERROR)
    },
    [acts.SET_CURRENT_ACTIVE_SCREEN]({commit, state}, nextScreen){
      commit(muts.SET_PREVIOUS_SCREEN, state.currentActiveScreen)
      commit(muts.SET_CURRENT_ACTIVE_SCREEN, nextScreen)
    },
  },
})
