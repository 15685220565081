import Vue from "vue";
import App from "./App.vue";
import "@/assets/css/tailwind.css";
import store from "./store";
import axios from "axios";
import utils from "@/services/utils";

import Clipboard from 'v-clipboard'

import router from "./router";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import errorCatcher  from '@/plugins/errorCatcher'
import repository from "./services/repository";
import '@/plugins/vue-toastification'
import '@/plugins/filter'
import '@/plugins/mixpanel'
import '@/plugins/v-hotjar'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
});

Vue.config.productionTip = false;
Vue.prototype.$axios = axiosInstance;
Vue.prototype.$utils = utils();
Vue.prototype.$api =  repository(axiosInstance)

Vue.use(Clipboard)

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DNS,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["https://widget.fluidcoins.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  beforeSend(event, hint) {
    const error = hint.originalException;
    const isRPCError = error && (error.message && error.message.toLowerCase().includes('No RPC Url available'.toLowerCase()))
    if(error && error.response && [400, 401,403, 409].includes(error.response.status) || isRPCError){
      return null
    }

    return event
  }
});

Vue.use(errorCatcher)

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
