<template>
    <Modal>
        <div class="bg-white w-10/12 md:w-9/12 mx-auto rounded-medium pt-8 overflow-hidden relative" @click.stop>
            <div class="px-8 pb-9">
                <h6 class="text-center text-error text-base md:text-lg font-medium">LINK EXPIRED</h6>
                <p class="text-sm md:text-base text-center text-grey400 pt-5">Note that Fluidcoins can not assure you of this rate again, so if your transfer comes in now, we will not record the transaction, rather we will refund you.</p>
            </div>
            <button class="text-sm md:text-base text-black bg-grey100 py-5 text-center font-medium tracking-wider w-full" @click="retry">Ok, Retry</button>
        </div>
        </Modal>
</template>

<script>
import Modal from '@/components/Modal/index.vue'
import { EventBus } from '@/services/event-bus'


import { Events } from '@/services/constants';

    export default {
        name: 'LinkExpired',
        components: {
            Modal
        },
        methods: {
            retry() {
                this.showNetworkInfoModal = false
                EventBus.$emit(Events.CLOSE_APP)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>