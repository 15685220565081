<template>
<!-- https://loading.io/css/ -->
    <div class="flex justify-center items-center h-full">
        <div class="lds-ripple"><div></div><div></div></div>
    </div>
</template>

<script>
    export default {
        name: 'Loader',
    }
</script>

<style lang="css" scoped>
    .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    }
    .lds-ripple div {
    position: absolute;
    border: 4px solid #A7A7A7;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
    }
</style>