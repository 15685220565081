<template>
    <div
    class="
      absolute
      bg-modal
      top-0
      left-0
      right-0
      bottom-0
      flex flex-col
      z-50
      rounded-large
    "
    @click="$emit('close')"
  >
    <div class="flex w-full h-full items-center">
        <slot />
    </div>
  </div>
</template>

<script>
    export default {
        name: 'Modal'
    }
</script>

<style lang="scss" scoped>
</style>