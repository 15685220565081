<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="200" height="200" fill="white" />
    <rect
      x="24"
      y="25"
      width="351.468"
      height="349.647"
      rx="102.688"
      fill="#131228"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M154.979 156.001V242.52C154.979 266.312 135.512 285.78 111.719 285.78V199.261C111.719 175.468 131.185 156.001 154.979 156.001Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M198.239 112.742H284.758C284.758 136.535 265.292 156.001 241.498 156.001H154.979C154.979 132.208 174.446 112.742 198.239 112.742Z"
      fill="white"
    />
    <path
      d="M219.868 242.702C231.913 242.702 241.679 232.937 241.679 220.891C241.679 208.845 231.913 199.08 219.868 199.08C207.822 199.08 198.057 208.845 198.057 220.891C198.057 232.937 207.822 242.702 219.868 242.702Z"
      fill="url(#paint0_linear_2200_5703)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2200_5703"
        x1="219.785"
        y1="257.008"
        x2="109.613"
        y2="179.385"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A8FF78" />
        <stop offset="1" stop-color="#1FFFBB" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Fluidcoins",
};
</script>

<style lang="scss" scoped>
</style>