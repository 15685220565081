<template>
  <button v-bind="$attrs" @click="$emit('click')">
    <svg
    
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 0C6.735 0 0 6.735 0 15C0 23.265 6.735 30 15 30C23.265 30 30 23.265 30 15C30 6.735 23.265 0 15 0ZM20.04 18.45C20.475 18.885 20.475 19.605 20.04 20.04C19.815 20.265 19.53 20.37 19.245 20.37C18.96 20.37 18.675 20.265 18.45 20.04L15 16.59L11.55 20.04C11.325 20.265 11.04 20.37 10.755 20.37C10.47 20.37 10.185 20.265 9.96 20.04C9.525 19.605 9.525 18.885 9.96 18.45L13.41 15L9.96 11.55C9.525 11.115 9.525 10.395 9.96 9.96C10.395 9.525 11.115 9.525 11.55 9.96L15 13.41L18.45 9.96C18.885 9.525 19.605 9.525 20.04 9.96C20.475 10.395 20.475 11.115 20.04 11.55L16.59 15L20.04 18.45Z"
      fill="white"
    />
  </svg>
  </button>
</template>

<script>
export default {
  name: "Cancel",
};
</script>

<style lang="scss" scoped>
</style>