<template>
    <Modal @close="$emit('close')">
        <div class="bg-white w-10/12 md:w-9/12 mx-auto rounded-medium pt-8 overflow-hidden relative" @click.stop>
            <div class="px-4 md:px-8 pb-9 flex flex-col">
                <h6 class="text-center text-black text-base md:text-lg font-medium">{{ hasDestinationTag ? 'DESTINATION TAG' : 'MEMO' }}</h6>
                <p class="text-sm md:text-base text-center text-black font-medium py-5">{{ text }}</p>
                <button class="bg-navyBlue text-white mx-auto rounded-3xl text-xs font-medium px-4 py-2" @click="copyValue(text)">COPY</button>
            </div>
            <button class="text-base text-black bg-grey100 py-5 text-center font-medium tracking-wider w-full" @click="$emit('close')">Close</button>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal/index.vue'
import CopyMixin from '@/mixins/copy-to-clipboard.js'
import { MIX_PANEL_EVENT } from '@/services/constants'
import {EventBus} from '@/services/event-bus'

    export default {
        name: 'AddressExtraInfoModal',
        mixins: [CopyMixin],
        props: {
            hasDestinationTag: {
                type: Boolean,
                required: true 
            },
            text: {
                type: Number,
                required: true
            }
        },
        components: {
            Modal
        },
        methods: {
            copyValue(value) {
                this.copy(value)
                if(this.hasDestinationTag){
                    EventBus.$emit(MIX_PANEL_EVENT.COPY_DESTINATION_TAG, {value})
                } else {
                    EventBus.$emit(MIX_PANEL_EVENT.COPY_MEMO, {value})
                }
                
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>