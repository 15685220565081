export const TRANSACTION = 'transaction'
export const PAYMENT_METHODS = 'payment_methods'
export const COINS = 'coins'
export const STEPPER_COUNT = 'stepper_count'

export const DASH_STROKE = 'dash_stroke'
export const TIMER ='timer'
export const TIMER_COLOR = 'timer_color'
export const START_COUNTDOWN = 'start_countdown'
export const START_TRANSACTION = 'start_transaction'
export const TRANSACTION_FAILURE_CONTENT = 'transaction_failure_content'
export const TRANSACTION_STATUS_PAYLOAD = 'transaction_status_payload' 
export const IS_RELAY_MODE ='is_relay_mode'
export const RELAY_DATA = 'relay_data'
export const CAN_VIEW_FEES_SHEET = 'canViewFeesSheet'
export const CAN_VIEW_RATE_SHEET = 'canViewRateSheet'
export const SELECTED_COIN = 'selectedCoin'
export const USE_PAY_WITH_TRANSFER_TEST_MODE = 'use_pay_with_transfer_test_mode'
export const ERROR_MESSAGE = 'errorMessage'
export const HAS_ERROR = 'has_error'

export const CURRENT_ACTIVE_SCREEN = 'current_active_screen'
export const SELECTED_NETWORK = 'selected_network'
export const SESSION_HAS_EXPIRED = 'session_has_expired'
export const CAN_SHOW_CLOSE_MODAL = 'can_show_close_modal'
export const IS_PROCESSING_SIMULATION = 'is_processing_simulation'
export const FIAT_CURRENCY = 'fiat_currency'
export const METAMASK_ACCOUNT = 'metamask_account'
export const PREVIOUS_SCREEN = 'previous_screen'