export const SET_COINS = 'set_coins'
export const SET_TRANSACTION = 'set_transaction'
export const SET_PAYMENT_METHOD = 'set_payment_method'
export const SET_STEPPER = 'set_stepper'

export const SET_TIMER = 'set_timer'
export const SET_TIMER_COLOR = 'set_timer_color'
export const SET_DASH_STROKE = 'set_dash_stroke'
export const SET_START_COUNTDOWN = 'set_start_countdown'
export const SET_START_TRANSACTION ='set_start_transaction'
export const SET_TRANSACTION_FAILURE_CONTENT = 'set_transaction_failure_content'
export const SET_TRANSACTION_STATUS_PAYLOAD = 'set_transaction_status_payload'
export const SET_RELAY_MODE = 'set_relay_mode'
export const SET_RELAY_DATA = 'set_relay_data'
export const SET_CAN_VIEW_FEES_SHEET = 'set_can_view_fees_sheet'
export const SET_CAN_VIEW_RATE_SHEET = 'set_can_view_rate_sheet'
export const SET_SELECTED_COIN = 'set_selected_coin'
export const SET_USE_PAY_WITH_TRANSFER_TEST_MODE = 'set_use_pay_with_transfer_test_mode'
export const SET_ERROR_MESSAGE = 'set_error_message'
export const SET_FIAT_CURRENCY = 'set_fiat_currency'
export const SET_METAMASK_ACCOUNT = 'set_metamask_account'

export const SET_CURRENT_ACTIVE_SCREEN = 'set_current_active_screen'
export const SET_PREVIOUS_SCREEN = 'set_previous_screen'
export const SET_SELECTED_NETWORK = 'set_selected_network'
export const SET_SESSION_HAS_EXPIRED = 'set_session_has_expired'
export const SET_CAN_SHOW_CANCEL_MODAL = 'set_can_show_cancel_modal'
export const SET_IS_PROCESSING_SIMULATION = 'set_is_processing_simulation'