<template>
    <Modal>
        <div class="bg-white w-10/12 mx-auto rounded-medium pt-8 overflow-hidden px-6" @click.stop>
            <h6 class="text-center text-error text-lg font-medium">CLOSE WIDGET</h6>
            <p class="text-grey400 text-center font-medium mt-4">Transaction isn’t completed yet. Are you sure you want to exit?</p>
            <div class="mt-6 space-y-4 mb-4">
                <button class="plain bg-navyBlue " @click="closeModal">
                    <span class="text--primary-gradient font-medium">No, Continue</span>
                </button>
                <button class="plain bg-grey text-black" @click="closeApp">Yes, Close</button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal/index.vue'
import { EventBus } from '@/services/event-bus'
import { Events, MIX_PANEL_EVENT } from '@/services/constants'
import * as muts from "@/store/mutations.types"
import { mapMutations} from 'vuex'

    export default {
        name: 'CloseModal',
        components: {
            Modal
        },
        methods: {
            ...mapMutations([muts.SET_CAN_SHOW_CANCEL_MODAL]),
            closeApp() {
                const {query} = this.$route
                const {referrer} = query
                EventBus.$emit(MIX_PANEL_EVENT.CLOSE_WIDGET, {referrer: referrer ? referrer : '*'})
                EventBus.$emit(Events.CLOSE_APP)
            },
            closeModal(){
                this[muts.SET_CAN_SHOW_CANCEL_MODAL](false)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>