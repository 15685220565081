<template>
    <div class="absolute top-4 flex justify-between items-center w-full h-10 pr-10 pl-4">
        <div class="w-7">
            <back role="button" aria-label="go back" @click="goBack" v-if="canShowBack" />
        </div>
        <p class="text-white font-semibold text-sm md:text-base" v-if="canShowTitle">{{ title }}</p>
        <p class="bg-brown py-2 px-3 text-black text-sm md:text-sm font-medium rounded-3xl" v-if="canShowTestMode">TEST MODE</p>
        <cancel role="button" aria-label="close" @click="attemptClose"/>
    </div>
</template>

<script>
import * as gets from "@/store/getters.types"
import * as muts from "@/store/mutations.types"
import { mapGetters, mapMutations} from 'vuex'
import { Events, SCREEN_NAME_ENUM } from '@/services/constants'
import CommonMixin from '@/mixins/common'
import { EventBus } from '@/services/event-bus'
import Cancel from '@/components/Icons/Cancel.vue'
import Back from '@/components/Icons/Back.vue'

    export default {
        name: 'Navigation',
        mixins: [CommonMixin],
        components: {
            Cancel,
            Back,
        },
        computed: {
            ...mapGetters([gets.CURRENT_ACTIVE_SCREEN, gets.TRANSACTION_STATUS_PAYLOAD, gets.SESSION_HAS_EXPIRED, gets.IS_RELAY_MODE, gets.IS_PROCESSING_SIMULATION, gets.PREVIOUS_SCREEN]),
            dashboardScreenActive(){
                return this.current_active_screen === SCREEN_NAME_ENUM.DASHBOARD
            },
            canShowTitle(){
                return this.current_active_screen === SCREEN_NAME_ENUM.CHOOSE_COIN || this.current_active_screen === SCREEN_NAME_ENUM.PROCESSING
            },
            canShowTestMode(){
                return this.isTestMode && this.dashboardScreenActive || this.current_active_screen === SCREEN_NAME_ENUM.SIMULATE
            },
            title(){
                if(this.current_active_screen === SCREEN_NAME_ENUM.PROCESSING){
                    return 'PROCESSING'
                }
                return 'CHOOSE COIN'
            },
            canShowBack(){
                return this.current_active_screen !== SCREEN_NAME_ENUM.PAYMENT_STATUS && this.current_active_screen !== SCREEN_NAME_ENUM.ERROR && !this.dashboardScreenActive
            }
        },
        methods: {
            ...mapMutations([muts.SET_CURRENT_ACTIVE_SCREEN, muts.SET_CAN_SHOW_CANCEL_MODAL]),
            goBack() {
                switch (this.current_active_screen) {
                    case SCREEN_NAME_ENUM.CHOOSE_COIN:
                    case SCREEN_NAME_ENUM.CHOOSE_METAMASK_ACCOUNT:
                    case SCREEN_NAME_ENUM.WALLET_CONNECT:
                        this[muts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.DASHBOARD)
                        break;
                    case SCREEN_NAME_ENUM.COIN_ADDRESS:
                        this[muts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.CHOOSE_COIN)
                        break;
                    case SCREEN_NAME_ENUM.PROCESSING:
                        if(this.previous_screen === SCREEN_NAME_ENUM.SIMULATE){
                            this[muts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.SIMULATE)
                        } else if(this.previous_screen === SCREEN_NAME_ENUM.CHOOSE_METAMASK_ACCOUNT){
                            this[muts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.CHOOSE_METAMASK_ACCOUNT)
                        } else {
                            this[muts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.COIN_ADDRESS)
                        }
                        break;
                
                    default:
                        this[muts.SET_CURRENT_ACTIVE_SCREEN](SCREEN_NAME_ENUM.DASHBOARD)
                        break;
                }
            },
            attemptClose(){
                const {paid_in_full, underpaid, overpaid } = this.transaction_status_payload
                if (this.session_has_expired || paid_in_full || underpaid || overpaid || (this.is_relay_mode && this.current_active_screen === SCREEN_NAME_ENUM.PROCESSING)) {
                    EventBus.$emit(Events.CLOSE_APP)
                    return;
                }
                this[muts.SET_CAN_SHOW_CANCEL_MODAL](true)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>