<template>
  <div class="flex-1 px-4 md:px-8 pt-20">
    <div class="w-40 h-40 mx-auto mb-14">
      <img :src="imgUrl" alt="success" class="w-full h-full object-cover" />
    </div>
    <div>
      <h6 class="text-xl md:text-2xl text-black font-medium text-center">{{ title }}</h6>
      <p class="text-grey400 text-center text-base mt-3">{{ description }}</p>
    </div>
    <button class="secondary mt-28">
      <span class="text--primary-gradient font-medium" @click="close">Close</span>
    </button>
  </div>
</template>

<script>
import * as gets from "@/store/getters.types";
import { mapGetters } from "vuex";
import { EventBus } from '@/services/event-bus';
import { Events } from '@/services/constants';

export default {
  name: "Success",
  computed: {
    ...mapGetters([gets.TRANSACTION_STATUS_PAYLOAD]),
    successPayload() {
      return this.transaction_status_payload;
    },
    isUnderPayment() {
      return !!this.successPayload.underpaid;
    },
    isOverPayment() {
      return !!this.successPayload.overpaid;
    },
    errorOccurred() {
      return this.successPayload.failed;
    },
    imgUrl() {
      if (this.isUnderPayment) {
        return "https://res.cloudinary.com/fluidcoins/image/upload/v1638873988/widget/underpaid_tkngk3.svg";
      }
      
      else if (this.isOverPayment) {
        return "https://res.cloudinary.com/fluidcoins/image/upload/v1638873988/widget/overpaid_juzslv.svg";
      }
      else if (this.errorOccurred) {
        return "https://res.cloudinary.com/fluidcoins/image/upload/v1638873988/widget/error_qgq4v1.svg";
      }

      return "https://res.cloudinary.com/fluidcoins/image/upload/v1638873877/widget/7698-success_pnv3x4.gif";
    },
    title() {
      if (this.isUnderPayment) {
        return "Underpaid";
      }
      else if (this.isOverPayment) {
        return "Overpaid";
      }
      else if (this.errorOccurred) {
        return "Failed";
      }

      return "Success";
    },
    description() {
      if (this.isUnderPayment) {
        return "Yikes! 😥 Looks like you paid lesser than required. Check your email to see next steps. Cheers ";
      }
      else if (this.isOverPayment) {
        return "Yikes! 😥  Looks like you paid more than required. Check your email to see next steps. Cheers!";
      }
      else if (this.errorOccurred) {
        return "Failed";
      }

      return "Your payment is successful 🎉. A receipt has been sent to your email. Cheers! ";
    },
  },
  methods: {
      close() {
          EventBus.$emit(Events.CLOSE_APP)
      }
  },
};
</script>

<style lang="scss" scoped>
</style>