<template>
  <div
    role="button"
    tabindex="0"
    class="flex items-center justify-between px-6 lg:px-8 bg-grey rounded-large"
    :class="{ 'py-6 lg:py-6': description, 'py-6 lg:py-8': !description}"
    @click="$emit('selected', title)"
  >
    
    <div class="flex items-center">
        <div class="h-6 w-6 md:h-8 md:w-8">
          <Icon :coin="coin" class="w-full h-full" v-if="coin" />
          <img :src="logoUrl" :alt="human_readable_name" class="w-full h-full object-contain" v-if="logoUrl"/>
      </div>
      <div class="ml-3">
        <p class="text-base md:text-lg text-black font-medium ">{{ title }}</p>
        <p class="text-xs md:text-sm text-grey400" v-if="description">{{ description }}</p>
      </div>
    </div>
    <div v-if="hasAmount">
      <p class="text-lg text-black font-medium">{{amount}} {{title}}</p>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Payments/Icon.vue'
export default {
  name: "CoinSelectItem",
  props: {
    coin: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    logoUrl: {
      type: String,
      default: ''
    },
    human_readable_name: {
      type: String,
      default: ''
    },
    hasAmount: {
      type: Boolean,
      default: false
    },
    amount: {
      type: String,
      default: '0.0'
    }
  },
  components: {
    Icon
  }
};
</script>

<style lang="scss" scoped>
</style>