/* eslint-disable no-unused-vars */
import * as gets from "@/store/getters.types";
import * as acts from "@/store/action.types";
import { mapGetters, mapActions } from "vuex";
import { generateOnRampURL } from "@coinbase/cbpay-js";

export default {
  name: "CoinbaseMixin",
  data() {
    return {};
  },
  computed: {
    ...mapGetters([gets.COINS]),
    usdcERC20Address() {
      const usdc = this.coins.find(
        (coin) => coin.code.toLowerCase() === "usdc"
      );
     
      if(usdc){
        const erc20Network = usdc.networks.find(
          (network) => network.name === "ERC20"
        );
        return erc20Network.address;
      }
     
      return  ''
    },
    onRampUrl() {
      if(!this.usdcERC20Address) return ''

      const destinationWallets = [
        {
          address: this.usdcERC20Address,
          assets: ["ETH", "USDC"],
        },
      ];

      const url = generateOnRampURL({
        appId: process.env.VUE_APP_COIN_BASE_KEY,
        destinationWallets,
      });

      return url;
    },
  },
  mounted() {},
  methods: {
    ...mapActions([acts.SET_CURRENT_ACTIVE_SCREEN]),
  },
};
